import * as yup from "yup";

const TmpIdentifiersSchema = yup.object().shape({
  author: yup.string().required("Please update TMP Author"),
  ractVersion: yup.string().required("Please update RACT Version"),
  ractVersionDate: yup
    .date()
    .typeError("RACT Version Date Invalid, Please use YYYY/MM/DD format")
    .required("Please update RACT Version Date"),
  revisionDescription: yup
    .string()
    .required("Please update Revision Description"),
  tmpVersion: yup.string().required("Please update TMP Version"),
  tmpVersionDate: yup
    .date()
    .typeError("TMP Version Date Invalid, Please use YYYY/MM/DD format")
    .required("Please update TMP Version Date"),
});

export const TmpSchema = yup.object({
  identifiers: yup
    .array()
    .of(TmpIdentifiersSchema)
    .required("Please complete all identifiers"),
  email: yup.string().email().required("Please provide valid email"),
});

export const initialValues = {
  recruitmentAndRetention: "",
  sourceDocumentation: "",
  informedConsent: "",
  sampleManagement: "",
  impImdManagement: "",
  studyMaterialsManagement: "",
  protocolCompliance: "",
  trialMaster: "",
  safetyManagement: "",
  investigationalSite: "",
  investigationalSiteCloseOut: "",
};
