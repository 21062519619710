import React from "react";

function ThankYou() {
  return (
    <div className="form h-48 flex justify-center items-center">
      Thank you for submitting the TMP Form
    </div>
  );
}

export default ThankYou;
