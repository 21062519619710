import React, { useMemo } from "react";
import FormDataError from "../../../components/Error/FormDataError";
import Definition from "./Definition";
import { getComponent } from "./GetComponent";
import Identifiers from "./Identifiers";
import SaveForLaterModal from "../../../components/Modals/SaveForLaterModal";
import SubmitModal from "../../../components/Modals/SubmitModal";

const Questions = ({
  data = [],
  questionIndex,
  handleNext,
  handleSubmit,
  disable,
  handleSetLocalStorage,
  handleReset,
  validateForm,
  values,
}) => {
  if (data.length === 0) return <FormDataError />;

  const indexedQuestion = data?.length && data[questionIndex];
  const Component =
    getComponent(indexedQuestion?.component, indexedQuestion) || "";
  const section = indexedQuestion?.section || "";
  const sectionTypeName =
    (!(indexedQuestion.info || indexedQuestion.landing) &&
      indexedQuestion?.sectionType?.value) ||
    false;
  const definition = indexedQuestion?.definition || "";
  const isSectionType =
    indexedQuestion?.sectionType &&
    `${indexedQuestion?.sectionType?.name}.${indexedQuestion?.name}`;
  const name = useMemo(
    () => isSectionType || indexedQuestion?.name || "",
    [isSectionType]
  );
  const identifiers = indexedQuestion?.identifiers?.length > 0 || false;
  const isInfo = !indexedQuestion?.info;

  return (
    <div className="font-rocheSans pt-[30px]">
      <img src="/blue.png" alt={section} className="" />
      {sectionTypeName && (
        <div className="text-xl pt-4 pb-2 flex items-center justify-between font-rocheSans uppercase font-semibold">
          {sectionTypeName}
        </div>
      )}
      <div className="text-[50pt] py-1 flex items-center justify-between font-rocheSerif mb-6">
        {!indexedQuestion.landing && section}
      </div>
      {identifiers && <Identifiers values={values} />}
      <div className="flex row gap-8">
        {definition && (
          <div className="w-1/2 flex-1">
            {definition && isInfo && <Definition definition={definition} />}
          </div>
        )}
        <div className={definition ? "w-1/2" : "w-full"}>
          {Array.isArray(Component) ? (
            Component
          ) : (
            <Component {...indexedQuestion} name={name} />
          )}
        </div>
      </div>
      <div
        className={`flex w-full gap-2 items-center mt-12 ${
          questionIndex !== 1 ? "justify-end" : "justify-start"
        }`}
      >
        {questionIndex === 0 ? (
          <>
            <a
              type="button"
              href="https://docs.google.com/forms/d/1pwdI24UNWCS-Vp5wPFkoZTUk3Rggtb6oiaf85Wkvv6w/formrestricted"
              target="_blank"
              className="roundedButton min-w-fit self-start"
              data-info={false}
            >
              Feedback/Support
            </a>
            <button
              type="button"
              onClick={handleNext}
              className="roundedButton min-w-fit self-start"
              data-info={false}
            >
              Next
            </button>
          </>
        ) : questionIndex === 1 ? (
          <button
            type="button"
            onClick={handleNext}
            className="roundedButton min-w-fit self-start"
            data-landing={true}
          >
            Let's Begin
          </button>
        ) : (
          <div className="flex gap-2 items-center">
            {!disable && <SubmitModal onClick={handleSubmit} />}
            {isInfo && <SaveForLaterModal onClick={handleSetLocalStorage} />}
            {disable ? (
              <SubmitModal
                onClick={handleSubmit}
                classes="submit_button border-0	"
              />
            ) : (
              <button
                type="button"
                onClick={handleNext}
                className="submit_button"
              >
                {"Next"}
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Questions;
