import { Field, useField } from "formik";
import React, { memo } from "react";
import EraserModal from "../components/Modals/EraserModal";
import NAModal from "../components/Modals/NAModal";

const TextArea = ({
  name,
  title = "",
  placeholder,
  classes,
  checkboxLabel,
  index,
}) => {
  if (name === null || name === "") return <div></div>;
  const [{ value: textAreaValue }, , { setValue }] = useField(name);
  const checkboxName = `${name}checked`;
  const [meta, , { setValue: setCheckboxValue }] = useField(checkboxName);
  return (
    <div key={index} className={classes}>
      {title && <h2>{title}</h2>}
      <Field
        className=""
        name={name}
        placeholder={placeholder}
        component="textarea"
        disabled={meta.value}
        autoFocus
      />
      {checkboxLabel ? (
        <div className="flex justify-end items-center w-full gap-4 my-4">
          <EraserModal
            onClick={() => {
              setValue("");
              setCheckboxValue(false);
            }}
          />
          <NAModal
            checkboxLabel={checkboxLabel}
            checkboxName={checkboxName}
            isChecked={meta.value}
            setCheckboxValue={setCheckboxValue}
          />
        </div>
      ) : null}
    </div>
  );
};
const arePropsEqual = (prev, next) => {
  return prev.name === next.name;
};
export default memo(TextArea, arePropsEqual);
