import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

function LogoutButton() {
  const { isAuthenticated, logout, user } = useAuth0();
  const handleLogout = () => {
    logout({ returnTo: `${window.location.origin}` });
  };
  return (
    isAuthenticated && (
      <div>
        <div className="absolute top-4 left-8 flex flex-row align-middle justify-center gap-4">
          {user.picture && (
            <img src={user.picture} alt={user.name} className="auth-logo" />
          )}
          <h2>{user.name}</h2>
        </div>
        <button className="auth top-3" onClick={handleLogout}>
          Log out
        </button>
      </div>
    )
  );
}

export default LogoutButton;
