import {
  DROPDOWN,
  EMAIL_INPUT,
  INFO,
  INPUT,
  MULTI_FORM,
  MULTI_INPUT,
  MULTI_TEXTAREA,
  RADIO,
  TEXTAREA,
  YOURENTRYTEXTAREA,
} from "../../../utils/constants";
import TextArea from "../../TextArea";
import RadioButton from "../../RadioButton";
import DropDown from "../../DropDown";
import Input from "../../Input";
import React from "react";
import Info from "../../Info";
import YourEntryTextArea from "../../YourEntryTextArea";
import EmailInput from "../../EmailInput";

export const getComponent = (component, indexedQuestion) => {
  switch (component) {
    case YOURENTRYTEXTAREA:
      return YourEntryTextArea;
    case TEXTAREA:
      return TextArea;
    case RADIO:
      return RadioButton;
    case DROPDOWN:
      return DropDown;
    case INPUT:
      return Input;
    case MULTI_INPUT:
      return indexedQuestion?.options.map((item, index) => {
        if (item.name)
          return (
            <div key={index}>
              <Input
                name={item.name}
                classes={item?.classes}
                title={item?.title}
                placeholder={item?.placeholder}
                {...item}
              />
            </div>
          );
      });
    case MULTI_TEXTAREA:
      return indexedQuestion?.options.map((item, index) => {
        if (item.name)
          return (
            <div key={index}>
              <TextArea
                name={item.name}
                classes={item?.classes}
                title={item?.title}
                placeholder={item?.placeholder}
                {...item}
              />
            </div>
          );
      });
    case MULTI_FORM:
      return indexedQuestion?.options.map((item, index) => {
        if (item.name && item.type === RADIO)
          return (
            <div key={index}>
              <RadioButton
                name={item.name}
                classes={item.classes}
                title={item.title}
                options={item.options}
                optionsClasses={item.optionsClasses}
              />
            </div>
          );
        if (item.name && item.type === DROPDOWN)
          return (
            <div key={index}>
              <DropDown
                name={item.name}
                classes={item.classes}
                title={item.title}
                options={item.options}
              />
            </div>
          );
        if (item.name && item.type === INPUT)
          return (
            <div key={index}>
              <Input
                name={item.name}
                classes={item.classes}
                inputClassName={item.inputClassName}
                title={item.title}
                options={item.options}
                placeholder={item.placeholder}
                {...item}
              />
            </div>
          );
        if (item.type === EMAIL_INPUT)
          return (
            <div key={index}>
              <EmailInput
                classes={item.classes}
                inputClassName={item.inputClassName}
                title={item.title}
                options={item.options}
                placeholder={item.placeholder}
                {...item}
              />
            </div>
          );
      });
    case INFO:
      return Info;

    default:
      return TextArea;
  }
};
