import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { Provider as ReduxQueryProvider } from "redux-query-react";
import { Auth0Provider } from "@auth0/auth0-react";
import TMPFormContainer from "./components/TMP/Tmp.container";
import configureStore, { getQueries } from "./axios/store";
import axiosNetworkInterface from "./axios/axiosNetworkInterface";
import configureApiClient from "./axios/configureApiClient";
import ThankYou from "./components/ThankYou/ThankYou";
import LandingPage from "./components/LandingPage/LandingPage";
import Header from "./components/Header/Header";
import ProtectedRoute from "./components/ProtectedRoute";
import config from "./config";
import WithLayout from "./patterns/WithLayout/WithLayout";

export const API_URL = `${
  // eslint-disable-next-line no-undef
  process.env.REACT_APP_API_URL || "http://localhost:8000" // Port for BE
}/`;
const apiClient = configureApiClient({ baseUrl: API_URL, url: API_URL });
const store = configureStore(axiosNetworkInterface(apiClient));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Auth0Provider
      domain={config.auth0.domain}
      clientId={config.auth0.clientId}
      redirectUri={config.urls.publicURL}
    >
      <Header />
      <Provider store={store}>
        <ReduxQueryProvider queriesSelector={getQueries}>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route
              path="/tmp"
              element={
                <ProtectedRoute>
                  <TMPFormContainer />
                </ProtectedRoute>
              }
            />
            <Route
              path="/author"
              element={
                <ProtectedRoute>
                  <WithLayout />
                </ProtectedRoute>
              }
            />
            <Route path="/form-submit" element={<ThankYou />} />
          </Routes>
        </ReduxQueryProvider>
      </Provider>
    </Auth0Provider>
  </BrowserRouter>
);
