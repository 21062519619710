import {
  applyMiddleware,
  combineReducers,
  createStore as createStoreRedux,
} from "redux";
import { entitiesReducer, queriesReducer, queryMiddleware } from "redux-query";

import { composeWithDevTools } from "redux-devtools-extension";

export const getQueries = (state) => state.queries;
export const getEntities = (state) => state.entities;

const reducer = combineReducers({
  entities: entitiesReducer,
  queries: queriesReducer,
});

const configureStore = (networkInterface) =>
  createStoreRedux(
    reducer,
    composeWithDevTools(
      applyMiddleware(
        queryMiddleware(networkInterface, getQueries, getEntities)
      )
    )
  );

export default configureStore;
