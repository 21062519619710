import { Field, useField } from "formik";
import React from "react";
import MaskedInput from "react-text-mask";

const dateMask = [/\d/, /\d/, /\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/];
const DateInput = (
  { name, title = "", placeholder, classes, inputClassName, label, labelClass },
  index
) => {
  if (name === null || name === "") return <div></div>;
  const [, meta] = useField(name);
  return (
    <div key={index} className={classes}>
      {title && <h2>{title}</h2>}
      <div>
        {label && <h2 className={labelClass}>{label}</h2>}
        <Field
          className={inputClassName}
          name={name}
          placeholder={placeholder}
          component="input"
          render={({ field }) => (
            <MaskedInput
              {...field}
              mask={dateMask}
              id="phone"
              placeholder="YYYY/MM/DD"
              type="text"
              onChange={field.onChange}
              onBlur={field.onBlur}
              className={
                meta.error && meta.touched ? "text-input error" : inputClassName
              }
            />
          )}
        />
      </div>
    </div>
  );
};
export default DateInput;
