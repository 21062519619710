import Input from "../../Input";
import React from "react";
import DateInput from "../../DateInput";

function getTitle(index, text) {
  return index === 0 ? text : "";
}

const Identifier = ({ index }) => {
  return (
    <div className="flex gap-4 items-end">
      <Input
        name={`identifiers[${index}].tmpVersion`}
        title={getTitle(index, "TMP Version")}
        inputClassName="identifierInput"
      />
      <DateInput
        name={`identifiers[${index}].tmpVersionDate`}
        title={getTitle(index, "TMP Version Date")}
        inputClassName="identifierInput"
      />
      <Input
        name={`identifiers[${index}].ractVersion`}
        title={getTitle(index, "RACT Version")}
        inputClassName="identifierInput"
      />
      <DateInput
        name={`identifiers[${index}].ractVersionDate`}
        title={getTitle(index, "RACT Version Date")}
        inputClassName="identifierInput"
      />
      <Input
        name={`identifiers[${index}].author`}
        title={getTitle(index, "TMP Author")}
        inputClassName="identifierInput"
      />
      <Input
        name={`identifiers[${index}].revisionDescription`}
        title={getTitle(index, "Revision Description")}
        inputClassName="identifierInput"
      />
      {/*<img*/}
      {/*  src="/blackCancel.svg"*/}
      {/*  className={*/}
      {/*    identifierArr.length - 1 === index*/}
      {/*      ? "h-10 w-10 w-full rotate-45"*/}
      {/*      : "h-10 w-10 w-full"*/}
      {/*  }*/}
      {/*  data-index={index}*/}
      {/*  onClick={(e) =>*/}
      {/*    identifierArr.length - 1 === index ? addRow() : handleDelete(e)*/}
      {/*  }*/}
      {/*/>*/}
    </div>
  );
};
export default Identifier;
