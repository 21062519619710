import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import LogoutButton from "../Buttons/LogoutButton";

const Header = () => {
  const [showHeader, setShowHeader] = useState(false);
  const location = useLocation();
  if (location.pathname === "/") return null;
  return (
    <div
      className="absolute h-4 top-0 left-0 w-full bg-amber-500  hover:bg-amber-300 flex justify-center items-center z-10"
      onMouseEnter={() => setShowHeader(true)}
      onMouseLeave={() => setShowHeader(false)}
    >
      <img src="./expand.svg" width="25px" height="25px" />
      {showHeader && (
        <div className=" absolute z-10 top-0 left-0 flex justify-center w-full bg-white">
          <img src="roche_brand_icon.png" className="h-6 m-6 " alt="Roche" />
          <LogoutButton />
        </div>
      )}
    </div>
  );
};

export default Header;
