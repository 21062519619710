const config = {
  development: {
    urls: {
      publicURL: "http://localhost:3000",
      apiURL: "http://127.0.0.1:8000",
    },
    auth0: {
      domain: "dev-7v4eazlfb4orwp5c.us.auth0.com",
      clientId: "lEhGmqqDR4smr8vKg1IPCIldIQVM0PJ9",
      redirectUri: window.location.origin,
    },
  },
  production: {
    urls: {
      publicURL: "https://roche.noomalabs.com",
      apiURL: "https://roche-api.noomalabs.com",
    },
    auth0: {
      domain: "nooma.us.auth0.com",
      clientId: "IbsjJxVVVS2vV6ss2uI7ZXxUZs1dZtvg",
      redirectUri: window.location.origin,
    },
  },
};

// eslint-disable-next-line no-undef
export default config[process.env.NODE_ENV];
