import React from "react";

const Definition = ({ definition }) => {
  return (
    <>
      <div className="guidance">
        <div className="py-2 font-semibold">Considerations:</div>
        <div
          className="whitespace-pre-line scrollbar scrollbar-track-blue-300"
          dangerouslySetInnerHTML={{ __html: definition }}
        />
      </div>
    </>
  );
};
export default Definition;
