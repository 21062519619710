import { Formik } from "formik";
import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import {
  clearStorage,
  getStorageItem,
  setStorageItem,
} from "../../utils/localstorage";
import WithLayout from "../../patterns/WithLayout/WithLayout";
import {
  createInitialValues,
  createYupSchema,
} from "../../utils/yupSchemaCreator";
import * as yup from "yup";

function FormContainer({
  initialValues,
  onSubmit,
  component: Component,
  validateOnBlur,
  validateOnChange,
  validateOnMount,
  enableReinitialize,
  resetForm,
  innerRef,
  validationSchema,
  formName,
  withLayout,
  data,
  injectSchema,
  ...props
}) {
  const indexData = data.map((item, index) => ({ ...item, index }));
  const yupSchema = indexData.reduce(createYupSchema, {});
  const concatSchema = yup.object().shape(yupSchema).concat(injectSchema);
  const createdInitialValues = useMemo(
    () => indexData.reduce(createInitialValues, {}),
    [indexData]
  );
  const localStorageItem = getStorageItem(formName);
  return (
    <Formik
      initialValues={localStorageItem || initialValues || createdInitialValues}
      onSubmit={onSubmit}
      enableReinitialize={enableReinitialize}
      validateOnBlur={validateOnBlur}
      validateOnChange={validateOnChange}
      validateOnMount={validateOnMount}
      resetForm={resetForm}
      innerRef={innerRef}
      validationSchema={concatSchema || validationSchema}
    >
      {(formikProps) => {
        const handleSetLocalStorage = useCallback(() => {
          setStorageItem(formName, formikProps.values);
        }, [setStorageItem, formikProps, formName]);
        const handleReset = useCallback(() => {
          clearStorage(formName);
          formikProps.handleReset();
        }, [formikProps, formName]);
        return withLayout ? (
          <WithLayout
            {...formikProps}
            {...props}
            handleReset={handleReset}
            data={indexData}
            handleSetLocalStorage={handleSetLocalStorage}
          />
        ) : (
          <Component
            {...formikProps}
            {...props}
            handleReset={handleReset}
            handleSetLocalStorage={handleSetLocalStorage}
          />
        );
      }}
    </Formik>
  );
}

FormContainer.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  initialValues: PropTypes.shape({}),
  data: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  enableReinitialize: PropTypes.bool,
  validateOnBlur: PropTypes.bool,
  validateOnChange: PropTypes.bool,
  validateOnMount: PropTypes.bool,
  withLayout: PropTypes.bool,
  resetForm: PropTypes.func,
  innerRef: PropTypes.shape({}),
  validationSchema: PropTypes.shape({}),
  injectSchema: PropTypes.shape({}),
};
FormContainer.defaultProps = {
  enableReinitialize: true,
  validateOnBlur: true,
  validateOnChange: true,
  withLayout: true,
  validateOnMount: false,
  resetForm: null,
  innerRef: null,
  validationSchema: {},
  data: [],
  initialValues: null,
};

export default FormContainer;
