import * as yup from "yup";
import {
  DROPDOWN,
  INPUT,
  MULTI_FORM,
  RADIO,
  TEXTAREA,
  YOURENTRYTEXTAREA,
} from "./constants";

function getValue(component) {
  switch (component) {
    case TEXTAREA:
    case YOURENTRYTEXTAREA:
    case INPUT:
    case RADIO:
    case DROPDOWN:
      return "";
    default:
      return "";
  }
}

const getValidation = (component) => {
  switch (component) {
    case TEXTAREA:
    case INPUT:
    case RADIO:
    case DROPDOWN:
      return "string";
    default:
      return "string";
  }
};
//https://codesandbox.io/s/clever-snyder-1u410?fontsize=14&file=/src/formData.js
export function createYupSchema(schema, config) {
  const {
    name,
    validations = [],
    component,
    required = null,
    section,
    sectionType,
    options,
    info,
  } = config;
  const validationType = getValidation(component);
  if (!yup[validationType] || info) {
    return schema;
  }
  let validator = yup[validationType]();
  validator = required
    ? validator["required"](`${section} is a required field`)
    : validator;
  validations.forEach((validation) => {
    const { params, type } = validation;
    if (!validator[type]) {
      return;
    }
    validator = validator[type](...params);
  });
  if (sectionType) {
    // schema[sectionType.name] = { [name]: validator };
    //TODO: FIX DEEP OBJECT VALIDATION
  }
  if (component === MULTI_FORM) {
    options.map((i) => {
      const itemValidationsType = getValidation(i.type);
      let optionsValidator = yup[itemValidationsType]();
      optionsValidator =
        i?.required &&
        optionsValidator["required"](`${i.title} is a required field`);
      schema[i.name] = optionsValidator;
    });
  } else {
    schema[name] = validator;
  }
  return schema;
}

const createIdentifiers = (identifier, el) => {
  identifier[el] = "";
  return identifier;
};

export const createInitialValues = (initialValue, data) => {
  const { name, component, identifiers, checkboxLabel, sectionType, options } =
    data;
  if (identifiers) {
    initialValue["identifiers"] = [
      { ...identifiers.reduce(createIdentifiers, {}) },
    ];
  }
  if (sectionType) {
    initialValue[sectionType.name] = {
      ...initialValue[sectionType.name],
      [name]: getValue(component),
    };
    if (checkboxLabel) {
      initialValue[sectionType.name] = {
        ...initialValue[sectionType.name],
        [`${name}checked`]: false,
      };
    }
  } else if (component === MULTI_FORM) {
    data.options.map((i) => (initialValue[i.name] = getValue(i.type)));
  } else {
    initialValue[name] = getValue(component);
    if (checkboxLabel) {
      initialValue[`${name}checked`] = false;
    }
  }
  return initialValue;
};

export function getSectionTypes(x, y) {
  const sectionName = y?.sectionType?.name;
  if (sectionName && !x[sectionName]) {
    x[sectionName] = false;
  }
  return x;
}
