import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useDevEnv } from "../../utils/__mock/mockLogin";

function LoginButton({ classes }) {
  const navigate = useNavigate();
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/tmp");
    }
  }, [isAuthenticated, navigate]);

  return (
    !isAuthenticated && (
      <button className={classes || "auth"} onClick={() => loginWithRedirect()}>
        Log In
      </button>
    )
  );
}

export default LoginButton;
