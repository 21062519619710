import { FieldArray } from "formik";
import Identifier from "./Identifier";
import React from "react";

const Identifiers = ({ values }) => (
  <div>
    IDENTIFIERS
    <FieldArray
      name="identifiers"
      render={(arrayHelpers) =>
        values?.identifiers &&
        values?.identifiers?.map((item, index) => (
          <Identifier
            key={index}
            index={index}
            identifierArr={values.identifiers}
            arrayHelpers={arrayHelpers}
          />
        ))
      }
    />
  </div>
);

export default Identifiers;
