import React, { useCallback, useState } from "react";

const SaveForLaterModal = ({ onClick }) => {
  const [showToolTip, setShowToolTip] = useState(false);
  let handleClick = () => setShowToolTip(!showToolTip);
  const handleConfirm = useCallback(() => {
    onClick();
    handleClick();
  }, [handleClick, onClick]);
  return (
    <>
      <button type="button" onClick={handleClick} className="button min-w-fit">
        Save for Later
      </button>
      {showToolTip && (
        <div className="fixed flex top-0 right-0 left-0 items-center justify-center bottom-0 bg-black/75 transition ease-in-out delay-150">
          <div className=" bg-white min-h-fit whitespace-pre m-auto w-1/3">
            <div className="w-full min-h-fit">
              <div className="bg-rocheBlue text-white text-3xl p-4 flex justify-end items-center">
                <div>
                  <img
                    src="/cancel.svg"
                    className="h-12 w-12 w-full cursor-pointer"
                    id="info-icon"
                    onClick={handleClick}
                  />
                </div>
              </div>
              <div className="whitespace-pre whitespace-pre-wrap p-4 font-semibold ">
                <div className="pb-4 text-center text-lg m-8">
                  The current data has been saved
                </div>
                <div className="flex justify-center gap-2">
                  <button
                    type="button"
                    className="roundedButton"
                    onClick={handleConfirm}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default SaveForLaterModal;
