import { Field, useField } from "formik";
import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const EmailInput = (
  { name, title = "", placeholder, classes, inputClassName, label, labelClass },
  index
) => {
  if (name === null || name === "") return <div></div>;

  const { user } = useAuth0();
  const [, , { setValue }] = useField("email");

  useEffect(() => {
    setValue(user.email);
  }, [user.email]);
  return (
    <div key={index} className={classes}>
      {title && <h2>{title}</h2>}
      <div>
        {label && <h2 className={labelClass}>{label}</h2>}
        <Field
          className={inputClassName}
          name="email"
          placeholder={placeholder}
          component="input"
        />
      </div>
    </div>
  );
};
export default EmailInput;
