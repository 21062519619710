import React from "react";
import LoginButton from "../Buttons/LoginButton";

function LandingPage() {
  return (
    <div className="fixed top-0 right-0 left-0 bottom-0 flex flex-col justify-center items-center">
      <div>
        <img src="roche_brand_icon.png" className="h-16 m-16" alt="Roche" />
      </div>
      <div>
        <LoginButton classes="button" />
      </div>
    </div>
  );
}

export default LandingPage;
