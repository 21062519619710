import axios from "axios";

export const removeEmpty = (value) => {
  if (value === "") return null;
  if (!value || typeof value !== "object") return value;

  const shape = JSON.parse(JSON.stringify(value));

  Object.keys(shape).forEach((key) => {
    shape[key] = removeEmpty(shape[key]);
  });

  return shape;
};

const axiosNetworkInterface =
  (axiosInstance) =>
  (url, method, { body, headers = {}, credentials = {} } = {}) => {
    let cancel;
    const { requestOptions, ...customHeaders } = headers;
    const requestConfig = {
      url,
      method,
      headers: {
        "Content-Type": "application/json",
        ...customHeaders,
      },
      data: !body || body instanceof FormData ? body : removeEmpty(body),
      withCredentials: credentials === "include",
      cancelToken: new axios.CancelToken((c) => {
        cancel = c;
      }),
      ...requestOptions,
    };
    const execute = (cb) =>
      axiosInstance(requestConfig).then(
        (response) => {
          const resStatus = (response && response.status) || 0;
          const resHeaders = (response && response.headers) || undefined;

          cb(null, resStatus, response, null, resHeaders);
        },
        (err) => {
          const resStatus = (err.response && err.response.status) || 0;
          const resHeaders =
            (err.response && err.response.headers) || undefined;
          const resData = (err.response && err.response.data) || {};

          cb(err, resStatus, resData, null, resHeaders);
        }
      );

    const abort = () => cancel();

    return {
      abort,
      execute,
    };
  };

export default axiosNetworkInterface;
