export const getSectionTypeStart = (section, data) => {
  if (data.sectionType) {
    const name = data.sectionType.name;
    const index = data.index;
    if (!section[name]) {
      section[name] = index;
    }
  }
  return section;
};
