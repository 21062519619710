import React, { useCallback, useMemo, useState } from "react";
import Questions from "./Questions/Questions";
import Sections from "./Sections/Sections";
import FormDataError from "../../components/Error/FormDataError";
import ErrorToolTip from "../../components/Tooltip/ErrorToolTip";
import { useFormikContext } from "formik";
import { getSectionTypeStart } from "../../utils/utils";
import { MULTI_FORM } from "../../utils/constants";

const WithLayout = ({
  data = [],
  handleBusinessLogic,
  validateForm,
  ...props
}) => {
  if (data.length === 0) return <FormDataError />;
  const [businessLogicCheck, setBusinessLogicCheck] = useState(false);
  const formikContext = useFormikContext();
  const [display, setDisplaySection] = useState([]);
  const [questionIndex, setIndex] = useState(0);
  const disable = useMemo(
    () => questionIndex === data.length - 1,
    [questionIndex, data]
  );
  const sectionTypeStart = data.reduce(getSectionTypeStart, {});
  const sectionTypeValues = Object.values(sectionTypeStart);
  const sectionTypeKeys = Object.keys(sectionTypeStart);
  const handleNext = useCallback(
    (index) => {
      if (data.length - 1 >= questionIndex) {
        if (typeof index === "number") {
          setIndex(index);
        } else if (!disable) {
          if (questionIndex === 0) {
            handleSection(sectionTypeKeys[0], "buttonClick");
          } else if (sectionTypeValues.includes(questionIndex + 1)) {
            const index = sectionTypeValues.indexOf(questionIndex + 1);
            handleSection(sectionTypeKeys[index], "buttonClick");
          }
          setIndex(questionIndex + 1);
        }
      }
      if (index?.target?.dataset?.landing === "true") {
        handleBusinessLogic();
        setBusinessLogicCheck(true);
      }
    },
    [data, setIndex, questionIndex, disable]
  );
  const handleSection = useCallback(
    (i, start) => {
      if (!display.includes(i)) {
        setDisplaySection([...display, i]);
      } else if (!start) {
        setDisplaySection(display.filter((x) => x !== i));
      }
    },
    [display, setDisplaySection]
  );

  const errorList = Object.keys(formikContext?.errors);

  const findErrorIndex = (e) => {
    const [filteredIndex] = data.filter((x) => {
      if (x.name === e) {
        return x;
      } else if (x.component === MULTI_FORM && x.options) {
        return x.options?.filter((y) => {
          if (y.name === e) {
            return x;
          }
        });
      }
    });
    return filteredIndex.index;
  };

  const isErrors =
    errorList.length > 0
      ? errorList.map((e) => {
          const errors = formikContext?.errors[e];
          return Array.isArray(errors)
            ? e === "identifiers"
              ? {
                  message: Object.values(errors[0]),
                  indexError: 1,
                }
              : {}
            : {
                message: errors,
                indexError: findErrorIndex(e),
              };
        })
      : [];
  const tmpVersion =
    props?.values &&
    props?.values.identifiers &&
    props?.values?.identifiers[0].tmpVersion;
  const ractVersion =
    props.values &&
    props.values.identifiers &&
    props.values.identifiers[0].ractVersion;

  const handleLandingPage = useCallback(
    (index) => {
      if (
        (typeof index === "number" &&
          (data[index].info || data[index].landing)) ||
        index.currentTarget?.dataset?.info
      ) {
        handleNext(index);
        return;
      }
      validateForm().then((res) => {
        if (Object.keys(res).length === 0) handleNext(index);
      });
    },
    [validateForm, handleNext]
  );
  return (
    <div className="flex relative h-full">
      <div
        className="w-1/6 bg-gradient-to-b from-white to-mauve py-4 float-left h-full overflow-y-auto pr-1 min-w-[175px] scrollbar-none"
        id="left-column"
      >
        <div className="flex justify-between items-center mb-8 mt-4 ">
          <img
            src="roche_brand_icon.png"
            className="h-12 pl-4 min-w-fit cursor-pointer"
            alt="Roche"
          />
          <div className="min-w-fit">
            <div className="border-b-2 border-b-black tracking-widest text-left py-2 pr-4 font-semibold last-of-type:border-0 ">
              {`TMP ${tmpVersion ? tmpVersion : "--"}`}
            </div>
            <div className="border-b-2 border-b-black tracking-widest text-left py-2 pr-4 font-semibold last-of-type:border-0 ">
              {`RACT ${ractVersion ? ractVersion : "--"}`}
            </div>
          </div>
        </div>
        <div>
          <Sections
            questionIndex={questionIndex}
            handleNext={handleLandingPage}
            data={data}
            sectionTypeValues={sectionTypeValues}
            handleSection={handleSection}
            display={display}
            {...props}
          />
        </div>
      </div>
      <div
        className="flex flex-col w-5/6 bg-gradient-to-bl from-blue-300 via-mauve to-mauve relative"
        id="right-column"
      >
        {isErrors.length > 0 ? (
          <ErrorToolTip
            tooltip={
              <div className="text-red-600 font-semibold pt-6 text-center list-none">
                {isErrors.map((i, key) => (
                  <li
                    key={key}
                    className="cursor-pointer hover:text-gray-200 text-white"
                    onClick={() => handleNext(i.indexError)}
                  >
                    {Array.isArray(i.message)
                      ? i.message.map((x) => <li>{x}</li>)
                      : i.message}
                  </li>
                ))}
              </div>
            }
          />
        ) : (
          <div className="pt-6" />
        )}
        <div className=" w-full p-4 pl-12 pr-16 overflow-y-auto">
          <Questions
            questionIndex={questionIndex}
            handleNext={handleLandingPage}
            disable={disable}
            data={data}
            {...props}
          />
        </div>
      </div>
    </div>
  );
};
export default WithLayout;
