import React from "react";

const ToolTip = ({ showToolTip, handleClick }) => {
  const refreshPage = () => {
    window.location.reload(false);
  };
  return (
    <>
      <div className="text-sm border-stone-900 mx-2 min-h-fit">
        {showToolTip && (
          <div className="fixed flex top-0 right-0 left-0 items-center justify-center bottom-0 bg-black/75 transition ease-in-out delay-150">
            <div className=" bg-white min-h-fit whitespace-pre m-auto w-1/3">
              <div className="w-full min-h-fit">
                <div className="bg-rocheBlue text-white text-3xl p-4 flex justify-end items-center">
                  <div>
                    <img
                      src="/cancel.svg"
                      className="h-12 w-12 w-full cursor-pointer"
                      id="info-icon"
                      onClick={handleClick}
                    />
                  </div>
                </div>
                <div className="whitespace-pre whitespace-pre-wrap p-4 font-semibold ">
                  <div className="pb-4 text-center text-lg m-8">
                    Thank you! You will receive an email momentarily
                  </div>
                  <div className="flex justify-center gap-2">
                    <button
                      type="button"
                      className="roundedButton"
                      onClick={() => {
                        handleClick();
                        refreshPage();
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default ToolTip;
