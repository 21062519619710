export const TEXTAREA = "textarea";
export const INPUT = "input";
export const EMAIL_INPUT = "emailInput";
export const RADIO = "radio";
export const DROPDOWN = "dropdown";
export const MULTI_INPUT = "multi-input";
export const MULTI_TEXTAREA = "multi-textarea";
export const MULTI_FORM = "multi-form";
export const INFO = "info";

export const YOURENTRYTEXTAREA = "yourEntryTextarea";
