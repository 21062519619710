import { Field } from "formik";
import React from "react";

const Input = (
  { name, title = "", placeholder, classes, inputClassName, label, labelClass },
  index
) => {
  if (name === null || name === "") return <div></div>;
  return (
    <div key={index} className={classes}>
      {title && <h2>{title}</h2>}
      <div>
        {label && <h2 className={labelClass}>{label}</h2>}
        <Field
          className={inputClassName}
          name={name}
          placeholder={placeholder}
          component="input"
        />
      </div>
    </div>
  );
};
export default Input;
