import React, { useCallback, useState } from "react";

const SectionType = ({
  handleSection,
  item,
  sectionsIndicator,
  display,
  handleNext,
}) => {
  const [hover, setHover] = useState(false);

  const handleOnClick = useCallback(() => {
    if (item?.info || item?.landing) {
      handleNext(item.index);
    } else {
      handleSection(item.sectionType.name);
    }
  }, [item, handleNext, handleSection]);
  const info = item.info ? "section-info" : "text-black";
  const showExpand = !(item?.info || item?.landing);
  return (
    <div className="relative">
      <div
        className={`font-semibold whitespace-pre-line py-4 pl-4 font-rocheSans flex justify-between w-full border-t-gray-400
           last-of-type:border-b-gray-400 border-y ${info}`}
        onClick={() => handleOnClick()}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div className="w-2/3 text-ellipsis overflow-hidden line-clamp-2 relative">
          <div>{item.sectionType.value}</div>
        </div>
        <div className="flex min-w-min items-center">
          {sectionsIndicator && sectionsIndicator[item?.sectionType?.name] && (
            <img
              src="/in-progress.svg "
              alt="check"
              width="50px"
              height="50px"
              className="w-6"
            />
          )}
          {showExpand && (
            <img
              src="/expand.svg"
              alt="expand"
              width="50px"
              height="50px"
              className={
                display.includes(item?.sectionType?.name)
                  ? "rotate-180 transition-transform h-12 cursor-pointer"
                  : "rotate-0 transition-transform h-12 cursor-pointer"
              }
            />
          )}
        </div>
      </div>
      {hover && (
        <div
          className="absolute bottom-0 right-0 translate-y-full bg-gray-100
         text-black font-semibold z-20 w-4/5 min-h-fit p-2 shadow rounded"
        >
          {item.sectionType.value}
        </div>
      )}
    </div>
  );
};
export default SectionType;
