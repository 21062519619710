import React from "react";
import FormDataError from "../../../components/Error/FormDataError";
import Section from "./Section";
import SectionType from "./SectionType";

const Sections = ({
  data = [],
  questionIndex,
  handleSection,
  display,
  sectionTypeValues,
  handleNext,
  sectionsIndicator,
  handleSectionsIndicator,
}) => {
  if (data.length === 0) return <FormDataError />;
  return (
    <div>
      <>
        {data.length > 0 &&
          data.map((item, i) => {
            return (
              <div key={i}>
                {sectionTypeValues.includes(i) && (
                  <SectionType
                    handleSection={handleSection}
                    item={item}
                    sectionsIndicator={sectionsIndicator}
                    display={display}
                    handleNext={handleNext}
                  />
                )}
                <div
                  className={
                    display.includes(item?.sectionType?.name) && !item.info
                      ? "block"
                      : "hidden"
                  }
                >
                  <Section
                    item={item}
                    i={i}
                    questionIndex={questionIndex}
                    handleNext={handleNext}
                    handleSectionsIndicator={handleSectionsIndicator}
                  />
                </div>
              </div>
            );
          })}
      </>
    </div>
  );
};
export default Sections;
