import React from "react";

function FormDataError() {
  return (
    <div className="form h-48 flex justify-center items-center">
      Form Data Incomplete
    </div>
  );
}

export default FormDataError;
