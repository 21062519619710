import { Field, useField } from "formik";
import React, { memo } from "react";
import EraserModal from "../components/Modals/EraserModal";
import NAModal from "../components/Modals/NAModal";

const Info = ({ index, classes, title, guidance }) => {
  return (
    <div key={index} className="font-rocheSans accent-rocheBlue">
      <div
        className="guidance"
        dangerouslySetInnerHTML={{ __html: guidance }}
      ></div>
    </div>
  );
};
const arePropsEqual = (prev, next) => {
  return prev.name === next.name;
};
export default memo(Info, arePropsEqual);
