import { Field } from "formik";
import React from "react";

const DropDown = ({ options, name, title, classes }) => (
  <div className={classes}>
    <h2>{title}</h2>
    <Field name={name} component="select">
      <option value=""> Please Select</option>
      {options.map(({ id, title }, index) => (
        <option key={index} value={id}>
          {title}
        </option>
      ))}
    </Field>
  </div>
);

export default DropDown;
