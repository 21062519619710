import { useField } from "formik";
import React, { useEffect, useMemo } from "react";

const Section = ({
  item,
  i,
  questionIndex,
  handleNext,
  handleSectionsIndicator,
}) => {
  const section = item.section;
  const sectionType = item.sectionType;
  const index = item.index || i;
  const name = sectionType ? `${sectionType.name}.${item.name}` : item.name;
  const checked = sectionType && `${sectionType.name}.${item.name}checked`;
  const field = useField(name);
  const checkedField = useField(checked || null);
  const isMulti = item?.component?.includes("multi");
  const isSelected = useMemo(
    () =>
      index === questionIndex
        ? "bg-blue-300"
        : " bg-transparent-300 hover:bg-transparent-700",
    [index, questionIndex]
  );
  let id = `section-${index}`;
  useEffect(() => {
    if (field[0].value || checkedField[0].value) {
      handleSectionsIndicator(sectionType?.name);
    }
    if (index === questionIndex) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }
  }, [index, questionIndex, id, field, checkedField, handleSectionsIndicator]);
  return (
    <>
      <div
        key={i}
        id={id}
        onClick={() => handleNext(index)}
        className={`flex justify-between items-center p-4 border-t-gray-400 
           last-of-type:border-b-gray-400 border-y ${isSelected}`}
      >
        <div className="text-blue-700 font-semibold whitespace-pre-line px-4 w-4/5 font-rocheSans">
          {section}
        </div>
        <div className="text-green-500 font-semibold flex">
          {isMulti ? (
            ""
          ) : (
            <img
              src={
                field[0].value || checkedField[0].value
                  ? "/checkmark.svg"
                  : "/empty.svg"
              }
              alt="check"
              width="50px"
              height="50px"
              className="text-lime-600 w-6"
            />
          )}
        </div>
      </div>
    </>
  );
};
export default Section;
