import React, { useState } from "react";
import { Field } from "formik";

const NAModal = ({
  checkboxLabel,
  checkboxName,
  isChecked,
  setCheckboxValue,
}) => {
  const [showToolTip, setShowToolTip] = useState(false);
  const handleClick = () => {
    setShowToolTip(!isChecked);
  };

  return (
    <>
      <div className="flex items-center border-l-2 border-gray-400">
        <label className="mx-2">{checkboxLabel}</label>
        <Field
          type="checkbox"
          name={checkboxName}
          className="checkbox"
          onClick={handleClick}
        />
      </div>
      {showToolTip && (
        <div className="fixed flex top-0 right-0 left-0 items-center justify-center bottom-0 bg-black/75 transition ease-in-out delay-150">
          <div className=" bg-white min-h-fit whitespace-pre m-auto w-1/3">
            <div className="w-full min-h-fit">
              <div className="bg-rocheBlue text-white text-3xl p-4 flex justify-end items-center">
                <div>
                  <img
                    src="/cancel.svg"
                    className="h-12 w-12 w-full cursor-pointer"
                    id="info-icon"
                    onClick={handleClick}
                  />
                </div>
              </div>
              <div className="whitespace-pre whitespace-pre-wrap p-4 font-semibold ">
                <div className="pb-4 text-center text-lg m-8">
                  By Selecting N/A, the data will not display in the TMP.
                </div>
                <div className="flex justify-center gap-2">
                  <button
                    type="button"
                    className="roundedButton"
                    onClick={handleClick}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default NAModal;
