import React, { useCallback, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import FormContainer from "../Form/Form.container";
import config from "../../config";
import { clearStorage } from "../../utils/localstorage";
import { tmpFormData } from "../../utils/forms/tmp/tmp";
import {
  createInitialValues,
  getSectionTypes,
} from "../../utils/yupSchemaCreator";
import SubmitToolTip from "../Tooltip/SubmitToolTip";
import { TmpSchema } from "./Tmp.validationSchema";

const formName = "TMP";

function TMPFormContainer() {
  const [data, setData] = useState(tmpFormData.data);
  const [showToolTip, setShowToolTip] = useState(false);
  let handleClick = () => setShowToolTip(!showToolTip);
  const [initialValues, setInitialValues] = useState(null);
  const [sectionsIndicator, setSections] = useState(null);
  const { user } = useAuth0();
  const formRef = useRef();

  const handleBusinessLogic = useCallback(() => {
    setInitialValues(formRef.current.values);
    const studyCentralMonitored = formRef.current.values.studyCentralMonitored;
    if (studyCentralMonitored !== "yes") {
      const removeCentralMonitoredSection = data.filter(
        (i) => i.sectionType && i.sectionType.name !== "studySpecificCm"
      );
      setData(removeCentralMonitoredSection);
      setSections(removeCentralMonitoredSection.reduce(getSectionTypes, {}));
    } else {
      setData(tmpFormData.data);
      setSections(tmpFormData.data.reduce(getSectionTypes, {}));
    }
  }, [formRef.current, setData, data]);

  const handleSubmit = useCallback((formData, actions) => {
    const url = `${config.urls.apiURL}/form/form-01`;
    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...formData }),
    })
      .then((response) => response.json())
      .then(() => {
        clearStorage(formName);
        actions.resetForm();
        handleClick();
        setInitialValues(tmpFormData.data.reduce(createInitialValues, {}));
      });
  }, []);

  const handleSectionsIndicator = useCallback(
    (sectionTypeName) => {
      if (sectionsIndicator != null && sectionTypeName) {
        sectionsIndicator[sectionTypeName] = true;
      }
    },
    [sectionsIndicator]
  );
  return (
    <>
      <FormContainer
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
        formName={formName}
        withLayout
        innerRef={formRef}
        handleBusinessLogic={handleBusinessLogic}
        validateOnBlur={false}
        validateOnChange={false}
        data={data}
        sectionsIndicator={sectionsIndicator}
        handleSectionsIndicator={handleSectionsIndicator}
        injectSchema={TmpSchema}
      />
      <SubmitToolTip showToolTip={showToolTip} handleClick={handleClick} />
    </>
  );
}

TMPFormContainer.propTypes = {};

export default TMPFormContainer;
