import React, { useCallback, useState } from "react";
import { useFormikContext } from "formik";

const SubmitModal = ({ onClick, classes }) => {
  const [showToolTip, setShowToolTip] = useState(false);
  const handleClick = () => setShowToolTip(!showToolTip);
  const handleConfirm = useCallback(() => {
    onClick();
    handleClick();
  }, [handleClick, onClick]);
  const removeBorder = classes && "border-r-0";
  const formikContext = useFormikContext();

  const errorList = Object.keys(formikContext?.errors);

  return (
    <>
      <div
        className={`border-r border-r-gray-300 pr-8 mr-8 h-16 flex items-center ${removeBorder}`}
      >
        <button
          type="button"
          onClick={handleClick}
          className={classes ? classes : "submit_button_black"}
          disabled={errorList?.length > 0}
        >
          Submit
        </button>
      </div>
      {showToolTip && (
        <div className="fixed flex top-0 right-0 left-0 items-center justify-center bottom-0 bg-black/75 transition ease-in-out delay-150">
          <div className=" bg-white min-h-fit whitespace-pre m-auto w-1/3">
            <div className="w-full min-h-fit">
              <div className="bg-rocheBlue text-white text-3xl p-4 flex justify-end items-center">
                <div>
                  <img
                    src="/cancel.svg"
                    className="h-12 w-12 w-full cursor-pointer"
                    id="info-icon"
                    onClick={handleClick}
                  />
                </div>
              </div>
              <div className="whitespace-pre whitespace-pre-wrap p-4 font-semibold ">
                <div className="pb-4 text-center text-lg m-8">
                  I agree that the sections left blank are not applicable to my
                  situation. I acknowledge that my ability to edit this plan
                  will end once I submit. Given this, I wish to proceed.
                </div>
                <div className="flex justify-center gap-2">
                  <button
                    type="button"
                    className="roundedButton"
                    onClick={handleClick}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="roundedButton"
                    onClick={handleConfirm}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default SubmitModal;
