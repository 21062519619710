import { guidanceInfo } from "./info";

export const formData = [
  {
    section: "General Guidance",
    sectionType: {
      name: "generalGuidance",
      value: "General Guidance",
    },
    name: "generalGuidance",
    component: "info",
    guidance: guidanceInfo,
    info: true,
  },
  {
    section: "TMP Home Page",
    sectionType: {
      name: "landing",
      value: "TMP Home Page",
    },
    component: "multi-form",
    landing: true,
    identifiers: [
      "tmpVersion",
      "tmpVersionDate",
      "ractVersion",
      "ractVersionDate",
      "author",
      "revisionDescription",
    ],
    options: [
      {
        name: "email",
        title: "Email",
        type: "emailInput",
        definition: "Email",
        required: true,
      },
      {
        name: "studyCentralMonitored",
        title: "Is the study centrally monitored?",
        type: "radio",
        options: [
          { id: "yes", title: "Yes" },
          { id: "no", title: "No" },
        ],
        required: true,
      },
      {
        name: "riskLevel",
        title: "Risk Level",
        classes: {},
        type: "dropdown",
        definition: "Please select a risk level",
        options: [
          { id: "High", title: "High" },
          { id: "Medium", title: "Medium" },
          { id: "Low", title: "Low" },
        ],
        required: true,
      },
      {
        name: "filename",
        title: "File Name",
        type: "input",
        definition: "File Name",
        required: true,
      },
      {
        name: "protocol",
        title: "Protocol Number",
        type: "input",
        definition: "Protocol",
        required: true,
      },
      {
        name: "siteStageRecruitment",
        title: "Recruitment: from site activation to last subject enrolled",
        type: "input",
        label: "arSMR Intervals",
        classes: "flex gap-2 my-2 justify-between w-2/3 items-end",
        labelClass: "text-center",
        placeholder: "# of weeks",
        definition:
          "Recruitment: from site activation to last subject enrolled",
        required: true,
      },
      {
        name: "siteStageTreatment",
        title:
          "Treatment: from last subject enrolled to last subject enters follow-up",
        type: "input",
        classes: "flex gap-2 my-2 justify-between w-2/3 items-end",
        placeholder: "# of weeks",
        definition:
          "Treatment: from last subject enrolled to last subject enters follow-up",
        required: true,
      },
      {
        name: "siteStageFollowUp",
        classes: "flex gap-2 my-2 justify-between w-2/3 items-end",
        title:
          "Follow-up: from last subject enters follow-up to clinical closure",
        type: "input",
        placeholder: "# of weeks",
        definition: "Follow-up: from last subject enters",
        required: true,
      },
      {
        name: "onsiteTrigger",
        classes: "flex gap-2 my-2 justify-between w-2/3 items-end",
        title: `Within  x-y days / weeks of ﬁrst subject screened or enrolled at site.`,
        labelClass: "text-center",
        label: "On-site Trigger",
        type: "input",
        placeholder: "x-y days / weeks",
        definition: "Triggers for conducting monitoring Activities",
        required: true,
      },
      {
        name: "onsiteSubject",
        type: "radio",
        definition: "Enrolled or Screened",
        title: "Enrolled or Screened",
        classes: "flex gap-2 my-2 justify-between w-2/3 items-center",
        optionsClasses: "flex justify-start w-72",
        options: [
          { id: "screened", title: "Screened" },
          { id: "enrolled", title: "Enrolled" },
        ],
        required: true,
      },
      {
        name: "earlyPatientDiscontinuation",
        title: "Provide any study-speciﬁc instructions for screen failures",
        labelClass: "text-center",
        classes: "flex gap-2 my-2 justify-between w-2/3 items-end",
        type: "input",
        required: true,
      },
      {
        name: "nonPerformingSites",
        title: `Non-performing sites are defined as follows:
          \t\n(E.g.: No subjects screened within 12 weeks following site activation\n
          These issues should be considered signiﬁcant and escalated and communicated immediately.)`,
        labelClass: "text-center",
        classes: "flex gap-2 my-2 justify-between w-2/3 items-end",
        type: "input",
        required: true,
      },
      {
        name: "collaborativeSiteLink",
        title: "Collaborative Site Link",
        labelClass: "text-center",
        classes: "flex gap-2 my-2 justify-between w-2/3 items-end",
        type: "input",
        required: true,
      },
    ],
  },
  {
    section: "Recruitment and Retention (including screen failures)",
    sectionType: {
      name: "studySpecificRdr",
      value: "Study-Speciﬁc Remote Monitoring Activities for CRA",
    },
    name: "recruitmentAndRetention",
    component: "yourEntryTextarea",
    definition: `•\tWhich study-specific portals does the ISM need access to? What are the concrete checks to be performed to review recruitment status and / or retention? 
•\tWhat are the criteria for your study to identify an inactive site? Provide possible mitigation actions that can be taken to boost recruitment, taking into account limitations per local regulations. As of when is escalation to local/global study team needed?
•\t Based on the identification of the critical eligibility criteria - by the study team - that should be reviewed by the ISM, describe the exact checks to be performed with possibe instructions on timing (when to review them) and mitigation actions in case of observed issues.
Note: it is very rare that eligibility criteria must be checked by CRA. Consider edit-checks or medical review first.`,
    checkboxLabel: "N/A",
  },
  {
    section: "Source Documentation and Data Entry",
    name: "sourceDocumentation",
    component: "yourEntryTextarea",
    definition: `•\tWhat protocol data is allowed to be entered directly into the eCRF (i.e. no prior written or electronic record of the data)? What does the ISM need to check for this data?
•\tWhat study-specific data sources will the ISM need access to (e.g. IxRS portal, vendor reports/portals etc)? What actions or checks does the ISM need to perform in these systems, and where should issues be documented?
•\tIf your study uses eCOA / ePRO, what checks need to be performed by the ISM, and at what frequency? Are there any paper scales related to the eCOAs supporting primary endpoints and how should these monitored? If there are issues with electronic data capture, what is the back-up option (eg paper questionnaires)?`,
    checkboxLabel: "N/A",
    sectionType: {
      name: "studySpecificRdr",
      value: "Study-Speciﬁc Remote Monitoring Activities for CRA",
    },
  },
  {
    section: "Informed Consent",
    name: "informedConsent",
    component: "yourEntryTextarea",
    definition: `•\tWhat types of ICFs are applicable for this study (e.g., main study / sub study, informed assent(s) for study [which may be age specific], optional consent for biosamples)? Are there any requirements to document consenting in systems other than CTMS?
•\tWhat study-specific checks need to be performed to ensure that all applicable consents for the study/site have been signed by the subject? What study-specific instructions need to be explained to the site (eg the importance of properly explaining and documenting optional sample consent)? Does the study use iCover? Provide instructions if study is utilizing eConsent and/or a mix of paper consent and eConsent with focus on systems where to document/track ICF dates.`,
    checkboxLabel: "N/A",
    sectionType: {
      name: "studySpecificRdr",
      value: "Study-Speciﬁc Remote Monitoring Activities for CRA",
    },
  },
  {
    section: "Sample Management",
    name: "sampleManagement",
    component: "yourEntryTextarea",
    definition: `•\tHow should ISMs report consent withdrawal to stop sample collection or request sample destruction? What instructions need to be given to sites in case a patient withdraws consent? Where should this be documented?
•\tDo ISMs need to order lab kits for their sites? Is there automatic or manual re-supply? How do the ISMs or sites order lab kits, and through which system/portal?
•\tAre there any study-specific portals which the ISM needs to be checking? What specifically needs to be verified? Which study-specific tracker needs to be completed or reviewed? Consider remaining stock vs planned visits in near future to trigger resupply if needed.`,
    checkboxLabel: "N/A",
    sectionType: {
      name: "studySpecificRdr",
      value: "Study-Speciﬁc Remote Monitoring Activities for CRA",
    },
  },
  {
    section: "IMP and/or IMD Management",
    name: "impImdManagement",
    component: "yourEntryTextarea",
    definition: `•\tWhat is considered IMP for this study (including comparator drug(s), if applicable)? What are the temperature storage requirements for the IMP? What study-specific IMP accountability actions need to be performed?
•\tIn case of subject diaries to record at home IMP intake, determine what is considered as source material for drug accountability: diaries or accountability log completed by pharmacist?
•\tDoes this study use any medical devices or rescue medication? What are the specific requirements for the handling and storage of the medical devices? What checks need to be performed by the ISM?
•\tAre there any safety instructions related to handling of IMP during drug accountability the ISM should be aware off?
•\tAre there both blinded and unblinded ISMs for this study? What different activities or processes need to be performed by the different roles? Where are these documented?
•\tWhich systems need to be checked by the ISM? What actions need to be performed in these systems by the ISM?`,
    checkboxLabel: "N/A",
    sectionType: {
      name: "studySpecificRdr",
      value: "Study-Speciﬁc Remote Monitoring Activities for CRA",
    },
  },
  {
    section: "Study Materials Management",
    name: "studyMaterialsManagement",
    definition: `•\tWhat additional study materials are provided to the sites (e.g. ePROs, questionnaires, patient diaries, ancillary supplies etc)? What actions do ISMs need to take to order/re-order these for the sites? Where does this need to be documented? How are these to be returned at the end of the study?
•\tIn case site is provided with rented study equipment (e.g. ECG machine), ensure a loan agreement is in place and the yearly calibration requirement (if needed) is adhered to and verified.
•\tWhich systems/vendors need to be checked by the ISM for the management of study materials?`,
    component: "yourEntryTextarea",
    checkboxLabel: "N/A",
    sectionType: {
      name: "studySpecificRdr",
      value: "Study-Speciﬁc Remote Monitoring Activities for CRA",
    },
  },
  {
    section: "Protocol Compliance",
    name: "protocolCompliance",
    component: "yourEntryTextarea",
    definition: `•\tWhat are the study-specific criteria for major protocol deviations? (either describe here or reference to Protocol Deviation Assessment Plan form SRD-0201764).
•\tWhich protocol assessments are required to be blinded or masked? What does the ISM need to train the site on for these assessments? What should the ISM be verifying for these assessments?
•\tWhich patient eligibility checks are the responsibility of the ISM, as per the RACT? In which system does this need to be checked (ie source data, central lab vendor portal etc.)? Do any checks need to be performed prior to patient enrollment?
•\tAre there any prohibited medications for which the ISM is responsible for reviewing?
•\tWhich assessments should the ISM be reviewing for compliance, as per the RACT (i.e. tumor assessments, visual acuity, EDSS scales, ...)? What information needs to be reviewed?
•\tWhich vendor systems or reports should the ISMs be reviewing for compliance (eg central imaging, photography, scales, ECGs or PROs)? What information needs to be reviewed?
•\tAre there any time-sensitive assessments or processes (e.g. sample submission to central lab for eligibility check) that could be monitored by the ISM? Provide instructions on source system and specific checks to perform incl. escalation path.`,
    checkboxLabel: "N/A",
    sectionType: {
      name: "studySpecificRdr",
      value: "Study-Speciﬁc Remote Monitoring Activities for CRA",
    },
  },
  {
    section: "Trial Master File and/or Essential Documents",
    name: "trialMaster",
    component: "yourEntryTextarea",
    definition: `•\tWhich study-specific documents need to be collected from the site and filed in the TMF (eg rater training certificates)? Where do these documents need to be collected from, and where should they be filed?
•\tFor studies with both blinded and unblinded site staff, what checks need to be performed by the ISM (eg delegation log, particular training requirements)?
•\tIf the study is using a particular system/portal for Regulatory Document Exchange, what actions does the ISM need to perform in this system?
•\tAre there co-development partners which need to be listed on the Financial Disclosure Form?`,
    checkboxLabel: "N/A",
    sectionType: {
      name: "studySpecificRdr",
      value: "Study-Speciﬁc Remote Monitoring Activities for CRA",
    },
  },
  {
    section: "Safety Management",
    name: "safetyManagement",
    component: "yourEntryTextarea",
    definition: `•\tAre there any study-specific actions (not covered in Standard Monitoring Activities (FOR-0300733) that the ISM needs to perform?`,
    checkboxLabel: "N/A",
    sectionType: {
      name: "studySpecificRdr",
      value: "Study-Speciﬁc Remote Monitoring Activities for CRA",
    },
  },
  {
    section: "Investigational Site Resource",
    name: "investigationalSite",
    component: "yourEntryTextarea",
    checkboxLabel: "N/A",
    definition: `•\tWhat are the study-specific equipment or room requirements? How often does study-specific verification/certification need to be checked/updated throughout the trial? Where should the ISM be documenting this?
•\tIs there additional study specific staff (eg. photographers, raters) needed at site and are there specific requirements (training, certification, etc.) to be verified and documented?
•\tHow should study-specific role types be captured in CTMS (eg rater)?`,
    sectionType: {
      name: "studySpecificRdr",
      value: "Study-Speciﬁc Remote Monitoring Activities for CRA",
    },
  },
  {
    name: "investigationalSiteCloseOut",
    section: "Investigational Site Close out",
    component: "yourEntryTextarea",
    checkboxLabel: "N/A",
    definition: `•\tWhat is the study-specific criteria for early termination of a site (e.g., non-enrolling sites may be closed after x months of non-activity, if no subjects have been recruited within x months of site activation)? Who confirms the decision to close a site?
•\tWhat equipment needs to be collected from the site or returned at site closure? Where can instructions for this be found?`,
    sectionType: {
      name: "studySpecificRdr",
      value: "Study-Speciﬁc Remote Monitoring Activities for CRA",
    },
  },

  {
    section: "Recruitment and Retention (including screen failures)",
    sectionType: {
      name: "studySpecificSdr",
      value: "Study-Speciﬁc On-site Monitoring Activities for CRA",
    },
    name: "recruitmentAndRetention",
    component: "yourEntryTextarea",
    definition: `•\tWhich study-specific portals (e.g., IxRS, eCOA etc.) does the ISM need access to? What are the concrete checks to be performed to review recruitment status and / or retention? 
•\tWhat are the criteria for your study to identify an inactive site? Provide possible mitigation actions that can be taken to boost recruitment, taking into account limitations per local regulations. As of when is escalation to local/global study team needed?`,
    checkboxLabel: "N/A",
  },
  {
    section: "Source Documentation and Data Entry",
    name: "sourceDocumentation",
    component: "yourEntryTextarea",
    definition: `•\tWhat protocol data is allowed to be entered directly into the eCRF (i.e. no prior written or electronic record of the data)? What does the ISM need to check for this data?
•\tWhat study-specific data sources will the ISM need access to (e.g. IxRS portal, vendor reports/portals etc)? What actions or checks does the ISM need to perform in these systems, and where should issues be documented?
•\tIf your study uses eCOA / ePRO, what checks need to be performed by the ISM, and at what frequency? Are there any paper scales related to the eCOAs supporting primary endpoints and how should these monitored? If there are issues with electronic data capture, what is the back-up option (eg paper questionnaires)?`,
    checkboxLabel: "N/A",
    sectionType: {
      name: "studySpecificSdr",
      value: "Study-Speciﬁc On-site Monitoring Activities for CRA",
    },
  },
  {
    section: "Informed Consent",
    name: "informedConsent",
    component: "yourEntryTextarea",
    definition: `•\tWhat types of ICFs are applicable for this study (e.g., main study / sub study, informed assent(s) for study [which may be age specific], optional consent for biosamples)? Are there any requirements to document consenting in systems other than CTMS?
•\tWhat study-specific checks need to be performed to ensure that all applicable consents for the study/site have been signed by the subject? What study-specific instructions need to be explained to the site (eg the importance of properly explaining and documenting optional sample consent)? Does the study use iCover? Provide instructions if study is utilizing eConsent and/or a mix of paper consent and eConsent with focus on systems where to document/track ICF dates.`,
    checkboxLabel: "N/A",
    sectionType: {
      name: "studySpecificSdr",
      value: "Study-Speciﬁc On-site Monitoring Activities for CRA",
    },
  },
  {
    section: "Sample Management",
    name: "sampleManagement",
    component: "yourEntryTextarea",
    definition: `•\tHow should ISMs report consent withdrawal to stop sample collection or request sample destruction? What instructions need to be given to sites in case a patient withdraws consent? Where should this be documented?
•\tDo ISMs need to order lab kits for their sites? Is there automatic or manual re-supply? How do the ISMs or sites order lab kits, and through which system/portal?
•\tAre there any study-specific portals which the ISM needs to be checking? What specifically needs to be verified? Which study-specific tracker needs to be completed or reviewed? Consider remaining stock vs planned visits in near future to trigger resupply if needed.`,
    checkboxLabel: "N/A",
    sectionType: {
      name: "studySpecificSdr",
      value: "Study-Speciﬁc On-site Monitoring Activities for CRA",
    },
  },
  {
    section: "IMP and/or IMD Management",
    name: "impImdManagement",
    definition: `•\tWhat is considered IMP for this study (including comparator drug(s), if applicable)? What are the temperature storage requirements for the IMP? What study-specific IMP accountability actions need to be performed?
•\tIn case of subject diaries to record at home IMP intake, determine what is considered as source material for drug accountability: diaries or accountability log completed by pharmacist?
•\tDoes this study use any medical devices or rescue medication? What are the specific requirements for the handling and storage of the medical devices? What checks need to be performed by the ISM?
•\tAre there any safety instructions related to handling of IMP during drug accountability the ISM should be aware off?
•\tAre there both blinded and unblinded ISMs for this study? What different activities or processes need to be performed by the different roles? Where are these documented?
•\tWhich systems need to be checked by the ISM? What actions need to be performed in these systems by the ISM?`,
    checkboxLabel: "N/A",
    sectionType: {
      name: "studySpecificSdr",
      value: "Study-Speciﬁc On-site Monitoring Activities for CRA",
    },
  },
  {
    section: "Study Materials Management",
    name: "studyMaterialsManagement",
    definition: `•\tWhat additional study materials are provided to the sites (e.g. ePROs, questionnaires, patient diaries, ancillary supplies etc)? What actions do ISMs need to take to order/re-order these for the sites? Where does this need to be documented? How are these to be returned at the end of the study?
•\tIn case site is provided with rented study equipment (e.g. ECG machine), ensure a loan agreement is in place and the yearly calibration requirement (if needed) is adhered to and verified.
•\tWhich systems/vendors need to be checked by the ISM for the management of study materials?`,
    component: "yourEntryTextarea",
    checkboxLabel: "N/A",
    sectionType: {
      name: "studySpecificSdr",
      value: "Study-Speciﬁc On-site Monitoring Activities for CRA",
    },
  },
  {
    section: "Protocol Compliance",
    name: "protocolCompliance",
    component: "yourEntryTextarea",
    definition: `•\tWhat are the study-specific criteria for major protocol deviations? (either describe here or reference to Protocol Deviation Assessment Plan form SRD-0201764).
•\tWhich protocol assessments are required to be blinded or masked? What does the ISM need to train the site on for these assessments? What should the ISM be verifying for these assessments?
•\tWhich patient eligibility checks are the responsibility of the ISM, as per the RACT? In which system does this need to be checked (ie source data, central lab vendor portal etc.)? Do any checks need to be performed prior to patient enrollment?
•\tAre there any prohibited medications for which the ISM is responsible for reviewing?
•\tWhich assessments should the ISM be reviewing for compliance, as per the RACT (i.e. tumor assessments, visual acuity, EDSS scales, ...)? What information needs to be reviewed?
•\tWhich vendor systems or reports should the ISMs be reviewing for compliance (eg central imaging, photography, scales, ECGs or PROs)? What information needs to be reviewed?
•\tAre there any time-sensitive assessments or processes (e.g. sample submission to central lab for eligibility check) that could be monitored by the ISM? Provide instructions on source system and specific checks to perform incl. escalation path.`,
    checkboxLabel: "N/A",
    sectionType: {
      name: "studySpecificSdr",
      value: "Study-Speciﬁc On-site Monitoring Activities for CRA",
    },
  },
  {
    section: "Trial Master File and/or Essential Documents",
    name: "trialMaster",
    component: "yourEntryTextarea",
    checkboxLabel: "N/A",
    definition: `•\tWhich study-specific documents need to be collected from the site and filed in the TMF (eg rater training certificates)? Where do these documents need to be collected from, and where should they be filed?
•\tFor studies with both blinded and unblinded site staff, what checks need to be performed by the ISM (eg delegation log, particular training requirements)?
•\tIf the study is using a particular system/portal for Regulatory Document Exchange, what actions does the ISM need to perform in this system?
•\tAre there co-development partners which need to be listed on the Financial Disclosure Form?`,
    sectionType: {
      name: "studySpecificSdr",
      value: "Study-Speciﬁc On-site Monitoring Activities for CRA",
    },
  },
  {
    section: "Safety Management",
    name: "safetyManagement",
    component: "yourEntryTextarea",
    checkboxLabel: "N/A",
    definition: `•\tAre there any study-specific actions (not covered in Standard Monitoring Activities (FOR-0300733) that the ISM needs to perform?`,
    sectionType: {
      name: "studySpecificSdr",
      value: "Study-Speciﬁc On-site Monitoring Activities for CRA",
    },
  },
  {
    section: "Investigational Site Resource",
    name: "investigationalSite",
    component: "yourEntryTextarea",
    checkboxLabel: "N/A",
    definition: `•\tWhat are the study-specific equipment or room requirements? How often does study-specific verification/certification need to be checked/updated throughout the trial? Where should the ISM be documenting this?
•\tIs there additional study specific staff (eg. photographers, raters) needed at site and are there specific requirements (training, certification, etc.) to be verified and documented?
•\tHow should study-specific role types be captured in CTMS (eg rater)?`,
    sectionType: {
      name: "studySpecificSdr",
      value: "Study-Speciﬁc On-site Monitoring Activities for CRA",
    },
  },
  {
    name: "investigationalSiteCloseOut",
    section: "Investigational Site Close out",
    component: "yourEntryTextarea",
    checkboxLabel: "N/A",
    definition: `•\tWhat is the study-specific criteria for early termination of a site (e.g., non-enrolling sites may be closed after x months of non-activity, if no subjects have been recruited within x months of site activation)? Who confirms the decision to close a site?
•\tWhat equipment needs to be collected from the site or returned at site closure? Where can instructions for this be found?`,
    sectionType: {
      name: "studySpecificSdr",
      value: "Study-Speciﬁc On-site Monitoring Activities for CRA",
    },
  },
  {
    section: "Recruitment and Retention (including screen failures)",
    sectionType: {
      name: "studySpecificCm",
      value: "Study-Speciﬁc CM (Central Monitor) Activities",
    },
    name: "recruitmentAndRetention",
    component: "yourEntryTextarea",
    definition: `•\tWhich study-specific portals (e.g., IxRS, eCOA etc.) does the ISM need access to? What are the concrete checks to be performed to review recruitment status and / or retention? 
•\tWhat are the criteria for your study to identify an inactive site? Provide possible mitigation actions that can be taken to boost recruitment, taking into account limitations per local regulations. As of when is escalation to local/global study team needed?`,
    checkboxLabel: "N/A",
  },
  {
    section: "Source Documentation and Data Entry",
    name: "sourceDocumentation",
    component: "yourEntryTextarea",
    definition: `•\tWhat protocol data is allowed to be entered directly into the eCRF (i.e. no prior written or electronic record of the data)? What does the ISM need to check for this data?
•\tWhat study-specific data sources will the ISM need access to (e.g. IxRS portal, vendor reports/portals etc)? What actions or checks does the ISM need to perform in these systems, and where should issues be documented?
•\tIf your study uses eCOA / ePRO, what checks need to be performed by the ISM, and at what frequency? Are there any paper scales related to the eCOAs supporting primary endpoints and how should these monitored? If there are issues with electronic data capture, what is the back-up option (eg paper questionnaires)?`,
    checkboxLabel: "N/A",
    sectionType: {
      name: "studySpecificCm",
      value: "Study-Speciﬁc CM (Central Monitor) Activities",
    },
  },
  {
    section: "Informed Consent",
    name: "informedConsent",
    component: "yourEntryTextarea",
    definition: `•\tWhat types of ICFs are applicable for this study (e.g., main study / sub study, informed assent(s) for study [which may be age specific], optional consent for biosamples)? Are there any requirements to document consenting in systems other than CTMS?
•\tWhat study-specific checks need to be performed to ensure that all applicable consents for the study/site have been signed by the subject? What study-specific instructions need to be explained to the site (eg the importance of properly explaining and documenting optional sample consent)? Does the study use iCover? Provide instructions if study is utilizing eConsent and/or a mix of paper consent and eConsent with focus on systems where to document/track ICF dates.`,
    checkboxLabel: "N/A",
    sectionType: {
      name: "studySpecificCm",
      value: "Study-Speciﬁc CM (Central Monitor) Activities",
    },
  },
  {
    section: "Sample Management",
    name: "sampleManagement",
    component: "yourEntryTextarea",
    definition: `•\tHow should ISMs report consent withdrawal to stop sample collection or request sample destruction? What instructions need to be given to sites in case a patient withdraws consent? Where should this be documented?
•\tDo ISMs need to order lab kits for their sites? Is there automatic or manual re-supply? How do the ISMs or sites order lab kits, and through which system/portal?
•\tAre there any study-specific portals which the ISM needs to be checking? What specifically needs to be verified? Which study-specific tracker needs to be completed or reviewed? Consider remaining stock vs planned visits in near future to trigger resupply if needed.`,
    checkboxLabel: "N/A",
    sectionType: {
      name: "studySpecificCm",
      value: "Study-Speciﬁc CM (Central Monitor) Activities",
    },
  },
  {
    section: "IMP and/or IMD Management",
    name: "impImdManagement",
    definition: `•\tWhat is considered IMP for this study (including comparator drug(s), if applicable)? What are the temperature storage requirements for the IMP? What study-specific IMP accountability actions need to be performed?
•\tIn case of subject diaries to record at home IMP intake, determine what is considered as source material for drug accountability: diaries or accountability log completed by pharmacist?
•\tDoes this study use any medical devices or rescue medication? What are the specific requirements for the handling and storage of the medical devices? What checks need to be performed by the ISM?
•\tAre there any safety instructions related to handling of IMP during drug accountability the ISM should be aware off?
•\tAre there both blinded and unblinded ISMs for this study? What different activities or processes need to be performed by the different roles? Where are these documented?
•\tWhich systems need to be checked by the ISM? What actions need to be performed in these systems by the ISM?`,
    checkboxLabel: "N/A",
    sectionType: {
      name: "studySpecificCm",
      value: "Study-Speciﬁc CM (Central Monitor) Activities",
    },
  },
  {
    section: "Study Materials Management",
    name: "studyMaterialsManagement",
    definition: `•\tWhat additional study materials are provided to the sites (e.g. ePROs, questionnaires, patient diaries, ancillary supplies etc)? What actions do ISMs need to take to order/re-order these for the sites? Where does this need to be documented? How are these to be returned at the end of the study?
•\tIn case site is provided with rented study equipment (e.g. ECG machine), ensure a loan agreement is in place and the yearly calibration requirement (if needed) is adhered to and verified.
•\tWhich systems/vendors need to be checked by the ISM for the management of study materials?`,
    component: "yourEntryTextarea",
    checkboxLabel: "N/A",
    sectionType: {
      name: "studySpecificCm",
      value: "Study-Speciﬁc CM (Central Monitor) Activities",
    },
  },
  {
    section: "Protocol Compliance",
    name: "protocolCompliance",
    component: "yourEntryTextarea",
    definition: `•\tWhat are the study-specific criteria for major protocol deviations? (either describe here or reference to Protocol Deviation Assessment Plan form SRD-0201764).
•\tWhich protocol assessments are required to be blinded or masked? What does the ISM need to train the site on for these assessments? What should the ISM be verifying for these assessments?
•\tWhich patient eligibility checks are the responsibility of the ISM, as per the RACT? In which system does this need to be checked (ie source data, central lab vendor portal etc.)? Do any checks need to be performed prior to patient enrollment?
•\tAre there any prohibited medications for which the ISM is responsible for reviewing?
•\tWhich assessments should the ISM be reviewing for compliance, as per the RACT (i.e. tumor assessments, visual acuity, EDSS scales, ...)? What information needs to be reviewed?
•\tWhich vendor systems or reports should the ISMs be reviewing for compliance (eg central imaging, photography, scales, ECGs or PROs)? What information needs to be reviewed?
•\tAre there any time-sensitive assessments or processes (e.g. sample submission to central lab for eligibility check) that could be monitored by the ISM? Provide instructions on source system and specific checks to perform incl. escalation path.`,
    checkboxLabel: "N/A",
    sectionType: {
      name: "studySpecificCm",
      value: "Study-Speciﬁc CM (Central Monitor) Activities",
    },
  },
  {
    section: "Trial Master File and/or Essential Documents",
    name: "trialMaster",
    component: "yourEntryTextarea",
    checkboxLabel: "N/A",
    definition: `•\tWhich study-specific documents need to be collected from the site and filed in the TMF (eg rater training certificates)? Where do these documents need to be collected from, and where should they be filed?
•\tFor studies with both blinded and unblinded site staff, what checks need to be performed by the ISM (eg delegation log, particular training requirements)?
•\tIf the study is using a particular system/portal for Regulatory Document Exchange, what actions does the ISM need to perform in this system?
•\tAre there co-development partners which need to be listed on the Financial Disclosure Form?`,
    sectionType: {
      name: "studySpecificCm",
      value: "Study-Speciﬁc CM (Central Monitor) Activities",
    },
  },
  {
    section: "Safety Management",
    name: "safetyManagement",
    component: "yourEntryTextarea",
    checkboxLabel: "N/A",
    definition: `•\tAre there any study-specific actions (not covered in Standard Monitoring Activities (FOR-0300733) that the ISM needs to perform?`,
    sectionType: {
      name: "studySpecificCm",
      value: "Study-Speciﬁc CM (Central Monitor) Activities",
    },
  },
  {
    section: "Investigational Site Resource",
    name: "investigationalSite",
    component: "yourEntryTextarea",
    checkboxLabel: "N/A",
    definition: `•\tWhat are the study-specific equipment or room requirements? How often does study-specific verification/certification need to be checked/updated throughout the trial? Where should the ISM be documenting this?
•\tIs there additional study specific staff (eg. photographers, raters) needed at site and are there specific requirements (training, certification, etc.) to be verified and documented?
•\tHow should study-specific role types be captured in CTMS (eg rater)?`,
    sectionType: {
      name: "studySpecificCm",
      value: "Study-Speciﬁc CM (Central Monitor) Activities",
    },
  },
  {
    name: "investigationalSiteCloseOut",
    section: "Investigational Site Close out",
    component: "yourEntryTextarea",
    checkboxLabel: "N/A",
    definition: `•\tWhat is the study-specific criteria for early termination of a site (e.g., non-enrolling sites may be closed after x months of non-activity, if no subjects have been recruited within x months of site activation)? Who confirms the decision to close a site?
•\tWhat equipment needs to be collected from the site or returned at site closure? Where can instructions for this be found?`,
    sectionType: {
      name: "studySpecificCm",
      value: "Study-Speciﬁc CM (Central Monitor) Activities",
    },
  },
  {
    section: "Recruitment and Retention (including screen failures)",
    sectionType: {
      name: "studySpecificOther",
      value: "Study-Speciﬁc Information (Other)",
    },
    name: "recruitmentAndRetention",
    component: "yourEntryTextarea",
    checkboxLabel: "N/A",
  },
  {
    section: "Source Documentation and Data Entry",
    name: "sourceDocumentation",
    component: "yourEntryTextarea",
    checkboxLabel: "N/A",
    sectionType: {
      name: "studySpecificOther",
      value: "Study-Speciﬁc Information (Other)",
    },
  },
  {
    section: "Informed Consent",
    name: "informedConsent",
    component: "yourEntryTextarea",
    checkboxLabel: "N/A",
    sectionType: {
      name: "studySpecificOther",
      value: "Study-Speciﬁc Information (Other)",
    },
  },
  {
    section: "Sample Management",
    name: "sampleManagement",
    component: "yourEntryTextarea",
    checkboxLabel: "N/A",
    sectionType: {
      name: "studySpecificOther",
      value: "Study-Speciﬁc Information (Other)",
    },
  },
  {
    section: "IMP and/or IMD Management",
    name: "impImdManagement",
    checkboxLabel: "N/A",
    component: "yourEntryTextarea",
    sectionType: {
      name: "studySpecificOther",
      value: "Study-Speciﬁc Information (Other)",
    },
  },
  {
    section: "Study Materials Management",
    name: "studyMaterialsManagement",
    component: "yourEntryTextarea",
    checkboxLabel: "N/A",
    sectionType: {
      name: "studySpecificOther",
      value: "Study-Speciﬁc Information (Other)",
    },
  },
  {
    section: "Protocol Compliance",
    name: "protocolCompliance",
    component: "yourEntryTextarea",
    checkboxLabel: "N/A",
    sectionType: {
      name: "studySpecificOther",
      value: "Study-Speciﬁc Information (Other)",
    },
  },
  {
    section: "Trial Master File and/or Essential Documents",
    name: "trialMaster",
    component: "yourEntryTextarea",
    checkboxLabel: "N/A",
    sectionType: {
      name: "studySpecificOther",
      value: "Study-Speciﬁc Information (Other)",
    },
  },
  {
    section: "Safety Management",
    name: "safetyManagement",
    component: "yourEntryTextarea",
    checkboxLabel: "N/A",
    sectionType: {
      name: "studySpecificOther",
      value: "Study-Speciﬁc Information (Other)",
    },
  },
  {
    section: "Investigational Site Resource",
    name: "investigationalSite",
    component: "yourEntryTextarea",
    checkboxLabel: "N/A",
    sectionType: {
      name: "studySpecificOther",
      value: "Study-Speciﬁc Information (Other)",
    },
  },
  {
    name: "investigationalSiteCloseOut",
    section: "Investigational Site Close out",
    component: "yourEntryTextarea",
    checkboxLabel: "N/A",
    sectionType: {
      name: "studySpecificOther",
      value: "Study-Speciﬁc Information (Other)",
    },
  },
  {
    section: "Recruitment and Retention (including screen failures)",
    sectionType: {
      name: "studySpecificUnblinded",
      value: "Instructions for Unblinded Study Team Members",
    },
    name: "recruitmentAndRetention",
    component: "yourEntryTextarea",
    checkboxLabel: "N/A",
  },
  {
    section: "Source Documentation and Data Entry",
    name: "sourceDocumentation",
    component: "yourEntryTextarea",
    checkboxLabel: "N/A",
    sectionType: {
      name: "studySpecificUnblinded",
      value: "Instructions for Unblinded Study Team Members",
    },
  },
  {
    section: "Informed Consent",
    name: "informedConsent",
    component: "yourEntryTextarea",
    checkboxLabel: "N/A",
    sectionType: {
      name: "studySpecificUnblinded",
      value: "Instructions for Unblinded Study Team Members",
    },
  },
  {
    section: "Sample Management",
    name: "sampleManagement",
    component: "yourEntryTextarea",
    checkboxLabel: "N/A",
    sectionType: {
      name: "studySpecificUnblinded",
      value: "Instructions for Unblinded Study Team Members",
    },
  },
  {
    section: "IMP and/or IMD Management",
    name: "impImdManagement",
    checkboxLabel: "N/A",
    component: "yourEntryTextarea",
    sectionType: {
      name: "studySpecificUnblinded",
      value: "Instructions for Unblinded Study Team Members",
    },
  },
  {
    section: "Study Materials Management",
    name: "studyMaterialsManagement",
    component: "yourEntryTextarea",
    checkboxLabel: "N/A",
    sectionType: {
      name: "studySpecificUnblinded",
      value: "Instructions for Unblinded Study Team Members",
    },
  },
  {
    section: "Protocol Compliance",
    name: "protocolCompliance",
    component: "yourEntryTextarea",
    checkboxLabel: "N/A",
    sectionType: {
      name: "studySpecificUnblinded",
      value: "Instructions for Unblinded Study Team Members",
    },
  },
  {
    section: "Trial Master File and/or Essential Documents",
    name: "trialMaster",
    component: "yourEntryTextarea",
    checkboxLabel: "N/A",
    sectionType: {
      name: "studySpecificUnblinded",
      value: "Instructions for Unblinded Study Team Members",
    },
  },
  {
    section: "Safety Management",
    name: "safetyManagement",
    component: "yourEntryTextarea",
    checkboxLabel: "N/A",
    sectionType: {
      name: "studySpecificUnblinded",
      value: "Instructions for Unblinded Study Team Members",
    },
  },
  {
    section: "Investigational Site Resource",
    name: "investigationalSite",
    component: "yourEntryTextarea",
    checkboxLabel: "N/A",
    sectionType: {
      name: "studySpecificUnblinded",
      value: "Instructions for Unblinded Study Team Members",
    },
  },
  {
    name: "investigationalSiteCloseOut",
    section: "Investigational Site Close out",
    component: "yourEntryTextarea",
    checkboxLabel: "N/A",
    sectionType: {
      name: "studySpecificUnblinded",
      value: "Instructions for Unblinded Study Team Members",
    },
  },
  {
    name: "smrRequirements",
    section: "Unblinded SMR Requirements and Issue Management",
    component: "yourEntryTextarea",
    checkboxLabel: "N/A",
    sectionType: {
      name: "studySpecificUnblinded",
      value: "Instructions for Unblinded Study Team Members",
    },
    definition: `Insert unblinded SMR instructions here.
•\tIn which system are the reports to be written?
•\tAt what frequency?
•\tWho will review the reports?

Provide guidance on Unblinded Issue Management
•\tWhere will issues be recorded?
•\tWho do these need to be escalated to?`,
  },
];
export const tmpFormData = {
  data: formData,
};
