export const guidanceInfo = `<div>
    <p ><div class="WordSection1">

<p class="MsoNormal" align="center" style="text-align:center"><b style="mso-bidi-font-weight:
normal"><u><span lang="EN">TMP General Guidance<o:p></o:p></span></u></b></p>

<p class="MsoNormal"><span lang="EN"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal"><span lang="EN"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal"><b style="mso-bidi-font-weight:normal"><span lang="EN">Steps
to generate a Trial Monitoring Plan (TMP):<o:p></o:p></span></b></p>

<p class="MsoNormal"><span lang="EN">1)  Export the 'Trial Monitoring Plan' summary from the RACT. <o:p></o:p></span></p>
<br>
<p class="MsoNormal"><span lang="EN">2) Answer the Study Setup questions on the TMP Home Page in order to create a TMP that is customized for your study. All fields on this page are mandatory, and most of the required information can be copied from the RACT Trial Monitoring Plan summary which was downloaded in step 1 (e.g. SMR intervals, first onsite visit trigger, study risk level). Once all fields on this page have been completed, click on the "Let's Begin" button.<o:p></o:p></span></p>
<br>
<p class="MsoNormal"><span lang="EN">3) There are 5 main sections in the TMP authoring tool, which can be seen in the menu on the left side of the screen. These are:
<li>Study-specific Remote Activities for CRA</li>
<li>Study-specific On-Site Monitoring Activities for CRA</li>
<li>Study-Specific CM (Central Monitor) Activities</li>
<li>Study-Specific Information (Other)</li>
<li>Instructions for Unblinded Study Team Members (if applicable)</li>
<br>
Under each of these headings are separate sections for the different TMP topics.
The next step now is to copy your control strategies and monitoring instructions from the RACT Trial Monitoring Plan Summary export into the appropriate section of the tool.
<p class="MsoNormal"><span lang="EN"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal"><span lang="EN">4) Now carefully review the 'Considerations' for each TMP topic. These considerations are intended as a prompt for you to consider whether there are any study-specific instructions that you will need to capture in the TMP for the Investigational Site Monitor (ie either CRA or CM) to follow. It is important to note that you should <strong>only add study-specific instructions. </strong> If an instruction is already mentioned in the Standard Monitoring Activities document (SMA) ( <a target="_blank" href="https://phoenixportal.roche.com/glossary/glossary/FOR-0300733"> <span style="font-size:11.5pt;line-height:115%;color:#1155CC">FOR-0300733</span></a>), then it should not be repeated in the Trial Monitoring Plan. It is expected that most of the TMP topics listed in the authoring tool will not require any study-specific instructions. If that is the case, you can either tick the N/A box, or simply leave this blank and move on to the next topic.
<p class="MsoNormal"><span lang="EN"><o:p>&nbsp;</o:p></span></p>
<p class="MsoNormal"><span lang="EN">5) If there is important information that the ISM needs to know to carry out the tasks assigned to them that is NOT a remote or onsite monitoring activity, this can be added in the 'Study-Specific Information (Other)' section.<o:p></o:p></span></p>
<p class="MsoNormal"><span lang="EN"><o:p>&nbsp;</o:p></span></p>
<p class="MsoNormal"><span lang="EN">6) When you have added all monitoring instructions and required information in the TMP Authoring Tool, click on the black 'Submit' button at the bottom of the screen. An email will be sent to you with your study-specific TMP attached. N.B. Once you have submitted the form, no further edits can be made in the TMP authoring tool, so make sure all required information has been entered before proceeding to the next step.<o:p></o:p></span></p>
<p class="MsoNormal"><span lang="EN"><o:p>&nbsp;</o:p></span></p>
<p class="MsoNormal"><span lang="EN">7) Review the TMP to ensure nothing is missing or incorrect. Any blank tables or rows which do not have any study-specific monitoring instructions should be deleted. Complete the appendix with links to relevant systems and study documents. At this point, the draft TMP should be sent to relevant study team members for review prior to finalization.<o:p></o:p></span></p>
<p class="MsoNormal"><span lang="EN"><o:p>&nbsp;</o:p></span></p>
<p class="MsoNormal"><span lang="EN">8) Finalize the TMP by saving it as a PDF, upload to eTMF and to CRA training portal. Provide TMP training to ISMs.<o:p></o:p></span></p>
<br>
<p class="MsoNormal"><span lang="EN"><o:p>&nbsp;</o:p></span></p>
<br>
<p class="MsoNormal"><b style="mso-bidi-font-weight:normal"><span lang="EN">Guidance
for writing good monitoring instructions:<o:p></o:p></span></b></p>

<p class="MsoNormal" style="margin-top:12.0pt;margin-right:0in;margin-bottom:
0in;margin-left:.5in;margin-bottom:.0001pt;text-indent:-.25in;mso-list:l1 level1 lfo1"><!--[if !supportLists]--><span lang="EN"><span style="mso-list:Ignore">●<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><!--[endif]--><b style="mso-bidi-font-weight:normal"><span lang="EN">Be specific: </span></b><span lang="EN">instructions should be clear so
that CRAs and CMs know which actions they need to perform. Don't be vague <span class="GramE">e.g.</span> 'review safety reporting'<o:p></o:p></span></p>

<p class="MsoNormal" style="margin-left:.5in;text-indent:-.25in;mso-list:l1 level1 lfo1"><!--[if !supportLists]--><span lang="EN"><span style="mso-list:Ignore">●<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><!--[endif]--><b style="mso-bidi-font-weight:normal"><span lang="EN">Be concise</span></b><span lang="EN">: context can be provided if it adds
value, but try to limit this as much as possible<o:p></o:p></span></p>

<p class="MsoNormal" style="margin-left:.5in;text-indent:-.25in;mso-list:l1 level1 lfo1"><!--[if !supportLists]--><span lang="EN"><span style="mso-list:Ignore">●<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><!--[endif]--><b style="mso-bidi-font-weight:normal"><span lang="EN">Do not duplicate</span></b><span lang="EN">: ensure you're not
duplicating other guidance, procedures, documents, ... If it already exists, do
not write a new instruction in this tool.<o:p></o:p></span></p>

<p class="MsoNormal" style="margin-left:.5in;text-indent:-.25in;mso-list:l1 level1 lfo1"><!--[if !supportLists]--><span lang="EN"><span style="mso-list:Ignore">●<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><!--[endif]--><b style="mso-bidi-font-weight:normal"><span lang="EN">Record only study-speciﬁc instructions </span></b><span lang="EN">not
included in the Standard Monitoring Activities document </span><span style="font-size:11.5pt;line-height:115%;color:#1155CC">(<a target="_blank" href="https://phoenixportal.roche.com/glossary/glossary/FOR-0300733">FOR-0300733</a>)</span></u><span lang="EN" style="font-size:11.5pt;line-height:115%">. Review the SMA before completing
this tool so you know what is already being reviewed as standard practice.</span><span lang="EN"><o:p></o:p></span></p>

<p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:.5in;text-indent:-.25in;mso-list:l1 level1 lfo1"><!--[if !supportLists]--><span lang="EN"><span style="mso-list:Ignore">●<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><!--[endif]--><b style="mso-bidi-font-weight:normal"><span lang="EN" style="font-size:11.5pt;line-height:115%">Maximize remote review</span></b><span lang="EN" style="font-size:11.5pt;line-height:115%"> as much as possible: only
add onsite/source access instructions if there is no way this can be reviewed
remotely.</span><span lang="EN"><o:p></o:p></span></p>

<p class="MsoNormal"><span lang="EN" style="font-size:11.5pt;line-height:115%"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal"><span lang="EN" style="font-size:11.5pt;line-height:115%">In
general, when you are writing the monitoring instructions for<span style="color:red"> </span>remote, onsite or Central Monitoring activities,
consider the following questions:<o:p></o:p></span></p>

<p class="MsoNormal"><span lang="EN" style="font-size:11.5pt;line-height:115%"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal" style="margin-top:11.0pt;margin-right:0in;margin-bottom:
0in;margin-left:.5in;margin-bottom:.0001pt;text-indent:-.25in;mso-list:l0 level1 lfo2"><!--[if !supportLists]--><span lang="EN" style="font-size:11.5pt;line-height:115%"><span style="mso-list:Ignore">●<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="EN" style="font-size:11.5pt;line-height:115%">What data/process does the
CRA need to verify? In what system?</span><span lang="EN"><o:p></o:p></span></p>

<p class="MsoNormal" style="margin-left:.5in;text-indent:-.25in;mso-list:l0 level1 lfo2"><!--[if !supportLists]--><span lang="EN" style="font-size:11.5pt;line-height:115%"><span style="mso-list:Ignore">●<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="EN" style="font-size:11.5pt;line-height:115%">How will the CRA verify the
data/process?</span><span lang="EN"><o:p></o:p></span></p>

<p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:11.0pt;
margin-left:.5in;text-indent:-.25in;mso-list:l0 level1 lfo2"><!--[if !supportLists]--><span lang="EN" style="font-size:11.5pt;line-height:115%"><span style="mso-list:Ignore">●<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><!--[endif]--><span lang="EN" style="font-size:11.5pt;line-height:115%">What does the CRA need to
document if there is an issue/deviation? In what system?</span><span lang="EN"><o:p></o:p></span></p>

<p class="MsoNormal"><b style="mso-bidi-font-weight:normal"><span lang="EN" style="font-size:11.5pt;line-height:115%">Purpose of a TMP:<o:p></o:p></span></b></p>

<p class="MsoNormal"><span lang="EN" style="font-size:11.5pt;line-height:115%">The
ISM is trained to monitor a study per ICH-GCP and most of the monitoring
requirements are the same for each study (<span class="GramE">e.g.</span> ICF,
drug accountability, ...). These activities are summarized in the </span><span lang="EN">Standard Monitoring Activities document</span></a> (SMA
<span style="font-size:11.5pt;line-height:115%;color:#1155CC"><a target="_blank" href="https://phoenixportal.roche.com/glossary/glossary/FOR-0300733">FOR-0300733</a>).<o:p></o:p></span></u></p>

<p class="MsoNormal"><span lang="EN" style="font-size:11.5pt;line-height:115%">If
there are risks or critical processes in a study that require additional review
to ensure good data quality and protection of patient's safety, well-being and
privacy, then these should be documented in this study-specific TMP. Therefore,
the ISM should always use the SMA and TMP in conjunction to know what and how
to monitor a study.<o:p></o:p></span></p>

<p class="MsoNormal"><span lang="EN" style="font-size:11.5pt;line-height:115%"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal"><span lang="EN" style="font-size:11.5pt;line-height:115%">Since
a TMP is a study-specific document that should fit the needs of a study, this
tool provides a flexible approach to customize a study TMP but it must always
meet the minimal TMP requirements as described in this document (</span><span lang="EN"><a target="_blank" href="https://phoenixportal.roche.com/glossary/glossary/SRD-0125119"><span style="font-size:11.5pt;line-height:115%;color:#1155CC">SRD-0125119 (TMP
Requirements)</span></a></span><span lang="EN" style="font-size:11.5pt;
line-height:115%">.<span style="mso-spacerun:yes">&nbsp; </span><o:p></o:p></span></p>

<p class="MsoNormal"><span lang="EN" style="font-size:11.5pt;line-height:115%">A
TMP is a living document that needs to be maintained and updated throughout a
study if there are changes impacting the monitoring activities.<span style="mso-spacerun:yes">&nbsp; </span>Please review </span><span lang="EN"><a target="_blank" href="https://phoenixportal.roche.com/glossary/glossary/SOP-0104647"><span style="font-size:11.5pt;line-height:115%;color:#1155CC">SOP-0104647
(Investigational Site Management)</span></a></span><span lang="EN" style="font-size:11.5pt;line-height:115%"> and</span><span lang="EN"><a target="_blank" href="https://phoenixportal.roche.com/glossary/glossary/SOP-0201115"><span style="font-size:11.5pt;line-height:115%;color:windowtext;text-decoration:none;
text-underline:none"> </span></a><a target="_blank" href="https://phoenixportal.roche.com/glossary/glossary/SOP-0201115"><span style="font-size:11.5pt;line-height:115%;color:#1155CC">SOP-0201115 (Study Risk
Based Quality Management)</span></a> for more information about the RBQM
approach and the expectations around site management</span><span lang="EN" style="font-size:11.5pt;line-height:115%">.<o:p></o:p></span></p>

<p class="MsoNormal"><span lang="EN" style="font-size:11.5pt;line-height:115%">In
addition to fulfilling the requirements of SRD-0125119, TMP authors must ensure
that they meet the monitoring requirements listed in other controlled documents
as listed below. These must be crossed checked by the TMP author and fulfilled
in the TMP:<o:p></o:p></span></p>

<p class="MsoNormal"><span lang="EN" style="font-size:11.5pt;line-height:115%"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal"><span lang="EN" style="font-size:11.5pt;line-height:115%">-</span><span lang="EN"><a target="_blank" href="https://phoenixportal.roche.com/glossary/glossary/68901_102568_236088/QUALITY_DOCS?detailsTab=details"><span style="font-size:11.5pt;line-height:115%;color:windowtext;text-decoration:none;
text-underline:none"> </span></a><a target="_blank" href="https://phoenixportal.roche.com/glossary/glossary/68901_102568_236088/QUALITY_DOCS?detailsTab=details"><span style="font-size:11.5pt;line-height:115%;color:#1155CC">SOP-0104650 (Global:
Informed Consent Forms)</span></a></span><span lang="EN" style="font-size:11.5pt;
line-height:115%"><o:p></o:p></span></p>

<p class="MsoNormal"><span lang="EN" style="font-size:11.5pt;line-height:115%">-</span><span lang="EN"><a target="_blank" href="https://phoenixportal.roche.com/glossary/glossary/SOP-0104924"><span style="font-size:11.5pt;line-height:115%;color:windowtext;text-decoration:none;
text-underline:none"> </span></a><a target="_blank" href="https://phoenixportal.roche.com/glossary/glossary/SOP-0104924"><span style="font-size:11.5pt;line-height:115%;color:#1155CC">SOP-0104924 (Global:
IMP Management)</span></a></span><span lang="EN" style="font-size:11.5pt;
line-height:115%"> and / or</span><span lang="EN"><a target="_blank" href="https://phoenixportal.roche.com/glossary/glossary/SOP-0121106"><span style="font-size:11.5pt;line-height:115%;color:windowtext;text-decoration:none;
text-underline:none"> </span></a><a target="_blank" href="https://phoenixportal.roche.com/glossary/glossary/SOP-0121106"><span style="font-size:11.5pt;line-height:115%;color:#1155CC">SOP-0121106 (Global:
Clinical Investigation for Medical Devices)</span></a></span><span lang="EN" style="font-size:11.5pt;line-height:115%"><o:p></o:p></span></p>

<p class="MsoNormal"><span lang="EN" style="font-size:11.5pt;line-height:115%">-</span><span lang="EN"><a target="_blank" href="https://phoenixportal.roche.com/glossary/glossary/SOP-0105983"><span style="font-size:11.5pt;line-height:115%;color:windowtext;text-decoration:none;
text-underline:none"> </span></a><a target="_blank" href="https://phoenixportal.roche.com/glossary/glossary/SOP-0105983"><span style="font-size:11.5pt;line-height:115%;color:#1155CC">SOP-0105983 (Global:
Protocol Deviations)</span></a></span><span lang="EN" style="font-size:11.5pt;
line-height:115%"><o:p></o:p></span></p>

<p class="MsoNormal"><span lang="EN" style="font-size:11.5pt;line-height:115%">-</span><span lang="EN"><a target="_blank" href="https://phoenixportal.roche.com/glossary/glossary/SOP-0105045"><span style="font-size:11.5pt;line-height:115%;color:windowtext;text-decoration:none;
text-underline:none"> </span></a><a target="_blank" href="https://phoenixportal.roche.com/glossary/glossary/SOP-0105045"><span style="font-size:11.5pt;line-height:115%;color:#1155CC">SOP-0105045 (Global:
Trial Master File)</span></a></span><span lang="EN" style="font-size:11.5pt;
line-height:115%"><o:p></o:p></span></p>

<p class="MsoNormal"><span lang="EN" style="font-size:11.5pt;line-height:115%">-</span><span lang="EN"><a target="_blank" href="https://phoenixportal.roche.com/glossary/glossary/SOP-0113120"><span style="font-size:11.5pt;line-height:115%;color:windowtext;text-decoration:none;
text-underline:none"> </span></a><a target="_blank" href="https://phoenixportal.roche.com/glossary/glossary/SOP-0113120"><span style="font-size:11.5pt;line-height:115%;color:#1155CC">SOP-0113120 (Global:
Safety Document Distribution)</span></a></span><u><span lang="EN" style="font-size:11.5pt;line-height:115%;color:#1155CC"><o:p></o:p></span></u></p>

<p class="MsoNormal"><span lang="EN"><o:p>&nbsp;</o:p></span></p>

</div></p>
  </div>`;
