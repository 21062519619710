import { Field } from "formik";
import React from "react";

const RadioButton = ({ name, options, classes, title, optionsClasses }) => (
  <div className={classes}>
    <h2>{title}</h2>
    <div className={optionsClasses}>
      {options.map(({ id, title: label }, index) => (
        <label key={index}>
          <Field type="radio" name={name} value={id} className="w-4" />
          <span className="mx-2">{label}</span>
        </label>
      ))}
    </div>
  </div>
);

export default RadioButton;
