import React from "react";

const ErrorToolTip = ({ tooltip }) => {
  return (
    <>
      {tooltip && (
        <div className="text-sm border-stone-900 relative min-h-fit bg-red-600 w-full min-h-min">
          {tooltip}
        </div>
      )}
    </>
  );
};
export default ErrorToolTip;
